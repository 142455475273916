<script>
import { Overlay } from "trading-vue-js";
import { getIndicatorSettings } from '@/extensions/indicatorSettings/defaultIndicatorSettings';
export default {
  name: "XMA",
  mixins: [Overlay],
  methods: {
    meta_info() {
      const indicatorName = 'XMA';
      const { period, lineWidth, lineType, color } = getIndicatorSettings(indicatorName);
      return {
        author: "LakshanPerera",
        version: "1.0.0",
        desc: "Exponential Moving Average",
        preset: {
          name: "EMA",
          side: "onchart",
          settings: {
            lineWidth,
            color,
            lineType,
            period
          },
        },
      };
    },
    use_for() {
      return ["XMA"];
    },
    calc() {
      return {
        props: {
          period: { def: this.meta_info().preset.settings.period, text: "Period" },
        },
        conf: { renderer: "Spline" },
        update: `
                    return ema(close, period)[0]
                `,
      };
    },
  },
};
</script>
