<template>
  <div class="w-full flex h-full overflow-auto" v-if="checkSize">

    <div class="w-9/12 flex flex-col justify-between">

      <div class="chart-comp" :class="'hidden'" v-if="!ischartshow">
        <div class="w-3/12 mt-2">
          <SelectCountry />
        </div>

        <div class="w-9/12 mt-2">
          <GuagesDial />
        </div>
          <div class=" w-12 container-wrapper" >
            
          </div>
          <div class="bottom-area" :class="checkSize ? 'hidden' : ''" v-if="ischartshow">
          <MarketChartPage/>
        <div class="bottom-widgetbar-handle" style="touch-action: none;"></div>
      </div>
      </div>

      <div class="w-full pr-1 chart-comp " >
        <Table v-on:size="sizeVal" :isFullSize="true"/>
      </div>
    </div>

    <div class="w-3/12 flex flex-col justify-between px-2 mt-1 ml-[10px]">
      <div class="w-full h-[32.5%] mt-[7px]">
        <AreaChart style="border: 0.3px solid rgba(233, 233, 233, 0.2); border-radius: 5px;" class="h-full" />
      </div>

      <div class="w-full calenderStyle mt-3">
        <calendar-component pageCalender="Market Alert" />
      </div>
    </div>


  </div>
    
    <div class="w-full flex h-full overflow-none" v-else>
      <!-- justify-between -->
    <div class="w-full flex flex-col ">

      <div class="flex" :class="checkSize ? 'hidden' : ''" v-if="!ischartshow">

        <div class="w-2/12 mt-2">
          <SelectCountry />
        </div>
        <div class="w-7/12 mt-2">
          <GuagesDial />
        </div>
        <div class="w-3/12 mt-2 mr-1">
          <AreaChart style="border: 0.3px solid rgba(233, 233, 233, 0.2); border-radius: 6px;" class="h-full" />
        </div>
      </div> 
      <div class="bottom-area" :class="checkSize ? 'hidden' : ''" v-if="ischartshow">
          <MarketChartPage/>
        <div class="bottom-widgetbar-handle" style="touch-action: none;"></div>
      </div>
      <div class="flex chart-comp " :class="{'mt-2 ' :true,}" >

        <div class="w-9/12 pr-1 mr-2 mt-2 ">
          <Table v-on:size="sizeVal" />
        </div>

        <div class="w-3/12 mt-3 mr-1">
          <calendar-component pageCalender="Market Alert" />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import SideBar from "../sidebar/SideBar.vue";
import SelectCountry from "../sidebar/SelectCountry.vue";
import CalendarComponent from "../calendar/CalendarComponent.vue";
import AreaChart from "../charts/AreaChart.vue";
import Table from "../tables/MarketWatchTableUpdated.vue";
// import GuagesDial2 from "@/components/sidebar/GuagesDial2";
import GuagesDial from "../sidebar/GuagesDial.vue";
import { mapActions } from "vuex";
import ChartPage from "../pages/ChartPage.vue";
// import VueResizable from "vue-resizable";
import { handler } from "tw-elements/dist/plugin";
import { ref } from 'vue'
// import {Resize} from '../pages/Resize.vue'
// import { methods } from "v-folder";
// import DropDownList from "../../components/popups/DropDownList.vue";
// Import component
// import DraggableResizableVue from 'draggable-resizable-vue3';
import MarketChartPage from "../pages/MarketChartPage.vue"

  



export default {
  name: "MarketWatch",


  components: {
    // GuagesDial2,
    GuagesDial,
    // SideBar,
    SelectCountry,
    CalendarComponent,
    AreaChart,
    ChartPage,
    Table,
    MarketChartPage
    
    // DropDownList,
  },
  data() {
    const tH = 400;
    return {
      ischartshow :false,
      isTableshow:true,
      minusValueOfHeight: 0,
      MarketWatchChartHeight: this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight - 300,
      size: false,
      checkSize: "",
      handlers: ["r", "rb", "b", "lb", "l", "lt", "t", "rt"],
      fit: true,
      event: "",
   elementTwo : ref({
  x: 180,
  y: 180,
  width: 300,
  height: 800,
  zIndex: 999,
  isActive: true,
})
    }
  },
computed:{
  // ...mapState(['viewChart']), 
    sharedData() {
      console.log(this.$store.state.viewChart,'viewchart')
      return this.$store.state.viewChart; // Access the shared data
    },
    flag() {
      return this.$store.state.flag;
    },
},
watch: {
  '$store.state.chartStore.viewChart': {
    handler(newVal, oldVal) {
      console.log('State changed:', newVal);
      this.ischartshow = newVal;
      this.isTableshow = !newVal;
    },
    deep: true ,
  },
  sharedData() {
    console.log(this.$store.state.viewChart,'viewchart')
    this.updatedval=this.$store.state.viewChart;   
    // return this.$store.state.viewChart;
    },
    flag(newValue, oldValue) {
      console.log(`Flag value changed from ${oldValue} to ${newValue}`);
    },
  },
  created() {
    // Log the initial flag value when the component is created
    console.log(`Initial flag value is ${this.flag}`);
  },

  async mounted() {
    console.log('monuted',$store.state.viewChart)
    // this.sharedData();
    // this.minusValueOfHeight = this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight / 2.65;
    this.MarketWatchChartHeight = this.$refs?.MarketWatchAreaChartDiv_Ref?.clientHeight - 300,
    console.log("Height", this.MarketWatchChartHeight)
    window.addEventListener('resize', this.OnResize)
  },
  methods: {
    // sharedData() {
    // console.log(this.$store.state.viewChart,'viewchart')
    //   return this.$store.state.viewChart; 
    // },
    ...mapActions(['loadIndexData', 'getUserData']),
    OnResize() {
      // this.$nextTick(()=>{
      //   this.minusValueOfHeight = this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight / 2.65
      // this.MarketWatchChartHeight = this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight - this.minusValueOfHeigh
      // })
      this.minusValueOfHeight = this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight / 2.65,
        this.MarketWatchChartHeight = this.$refs?.MarketWatchAreaChartDiv_Ref?.clientHeight - 300
      // this.MarketWatchChartHeight = this.$refs.MarketWatchAreaChartDiv_Ref?.clientHeight - this.minusValueOfHeight
    },
    sizeVal(e) {
      // console.log("value:",e)
      this.checkSize = e
    },
    updateFlag(newFlag) {
      // this.flag = newFlag; 
      console.log(newFlag ,'newFlag')
    },
    eHandler(data) {
      // this.width = data.width;
      this.height = data.height;
      this.left = data.left;
      this.top = data.top;
      this.event = data.eventName;
    },
  },
  async mounted() {
    await this.getUserData()
    this.$store.commit("SET_CATEGORY_FOR_DATES", "market_alert")
  }
};

</script>



<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");

span {}
</style>
<style>
.block {
  height: 100%;
  width: 100%;
  /* border: 1px solid red; */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}
.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.calenderStyle {
  height: 65%
}
 .chart-comp{
  overflow: auto;
} 
.content-wrapper{
  overflow: auto;
  height: 96vh;
}
.chart-wrapper{
  position: relative
}
.container-wrapper {
  /* width: 80vw;
  height: 100vh; */
  /* display: inline-block; */
  border: 1px solid #dddddd;
  /* background: #ffffff; */
  color: #333333;
  /* float: left; */
  margin: 10px;
}
.bottom-widgetbar-handle {
    cursor: ns-resize;
    height: 7px;
    left: 0;
    /* position: absolute; */
    top: -44px;
    width: 100%;
    z-index: 2;
}
.bottom-area{
  position: relative;
  /* height: 35%; */
}

.resizable {
  /* background-position: top left; */
  width: 100%;
  border:3px solid red;
  padding: 0;
  font-weight: normal;
  position: relative;
}
.table-wrapper{
  /* height: 100vh; */
}
.element-two {
  background-color: yellow;
}
@media only screen and (max-width: 1550px) {
  /* .calenderStyle {
    height: 64.5%
  } */

}
@media (min-width: 1024px) { 
  .calender-m-top{
    /* margin-top: 80px!important; */
  }
 }
</style>