export const getIndicatorSettings = (indicatorName) => {
  const storedSettings = JSON.parse(localStorage.getItem('indicators_settings')) || [];
  const symbolSelected = localStorage.getItem('SymbolSelected');
  const matchingItem = storedSettings.find(item => item.symbolname === symbolSelected && item.uuid === indicatorName);

  const defaultSettingsByIndicator = {
    // Add default settings for each indicator name
    'SMAX': {
      period: 25,
      lineWidth: 0.5,
      color: '#d1385c',
      lineType: 'solid',
    },
    'WMA': {
      period: 15,
      lineWidth: 0.5,
      color: '#e57440',
      lineType: 'solid',
    },
    'SAR': {
      period: null,
      lineWidth: 0.8,
      color: '#35a9c6',
      lineType: null,
      start: 0.02,
      inc: 0.02,
      max: 0.02,
    },
    'MACD': {
      fast: 24,
      slow: 26,
      smooth: 9,
      histWidth: 4,
      macdWidth: 0.5,
      signalWidth: 0.5,
      macdColor:'#3782f2',
      signalColor: '#f48709',
      histColor: '#9954bb',
    },
    'RSI': {
      period: 2,
      lineWidth: 0.5,
      color: '#ec206e',
      lineType: null,
      bandColor: "#aaa",
      backColor: '#9b9ba316',
    },
    'Stoch': {
      period: null,
      lineWidth: 0.75,
      color: null,
      lineType: null,
      kColor: '#3782f2',
      dColor: '#f48709',
      bandColor: '#ddd',
      backColor: '#381e9c16',
      param_k:14,
      param_d:3,
      smooth:3,
    },
    'ROC': {
      period: 9,
      lineWidth: 0.5,
      color: '#279fc4',
      lineType: 'solid',
    },
    'BB': {
      period: 21,
      lineWidth: 0.5,
      color: '#2cc6c9ab',
      lineType: null,
      backColor:'#2cc6c94d',
      stddev:2,
    },
    'XMA': {
      period: 12,
      lineWidth: 0.5,
      color: '#f7890c',
      lineType: 'solid',
    },
    'WilliamsR': {
      period: 14,
      lineWidth: 0.75,
      color: '#0980e8',
      lineType: null,
      bandColor:"#aaa",
      backColor:'#9b9ba316',
    },
    'TCI': {
      period: 34,
      lineWidth: 1,
      color: 'rgba(39, 128, 227, 1)',
      lineType: null,
      bandColor:'rgba(39, 128, 227, 0.1)'  //here
    },
    'VWAP': {
      period: null,
      lineWidth: 0.5,
      color: 'rgba(39, 128, 227, 1)',
      lineType: null,
    },
    'VolumePrimary': {
      period: null,
      lineWidth: 0.5,
      color: 'rgba(39, 128, 227, 1)',
      lineType: null,
    },
    'Value': {
      period: null,
      lineWidth: 0.5,
      color: '#e52468',
      lineType: 'solid',
    },
    'SMAVolume': {
      period: 14,
      lineWidth: 0.5,
      color: 'dodgerblue',
      lineType: null,
    },
    'SMAOBV': {
      period: 5,
      lineWidth: 0.5,
      color: 'red',
      lineType: null,
    },
    'ATR': {
      period: 15,
      lineWidth: 0.5,
      color: '#e52468',
      lineType: 'solid',
    },

    // Add default settings for other indicators
  };

  // Check if matchingItem exists and it has a period value
  if (matchingItem) {
    const defaultSetting = defaultSettingsByIndicator[indicatorName];
    // Iterate over matchingItem keys to update the default settings if those keys exist
    Object.keys(matchingItem).forEach(key => {
      // Check if the key is not 'symbolname' or 'uuid'
      if (key !== 'symbolname' && key !== 'uuid' && defaultSetting.hasOwnProperty(key)) {
        // Update the default settings with the value from matchingItem
        defaultSetting[key] = matchingItem[key];
      }
    });
    return defaultSetting;
  } else {
    // If matchingItem doesn't exist or it doesn't have a period value, return default settings
    return defaultSettingsByIndicator[indicatorName] || {
      // Default settings if no specific settings found for the indicator
      period: 25,
      lineWidth: 0.5,
      lineType: 'solid',
      color: '#d1385c'
    };
  }
};
