<template>
  <div class="bg-[#151515] px-2">
    <ChartModal @HideModal="HideModal" :showModal="showModal" />
    <!-- <create-watch-modal></create-watch-modal> -->
    <div class="w-full h-[94vh]" ref="chartPageContainer">
      <!-- <div class="block max-w-xl h-fit " id="chartId"> -->
        <div class="form-group pb-2 flex gap-2 items-center" ref="searchContainer" style="width: 70vw;">
          <h1 class="text-[#F8F9F9] text-[1.6vw] Inter-Bold pr-[2vw]"
            style="font-family: 'Inter'; font-style: normal; font-weight: 700">&nbsp;Chart
          </h1>


          <div class="w-[25vw] h-[3.5vh]">

            <div class="w-full flex items-center">
              <!-- @focusout="handleFocusOut" -->

              <input type="text" id="findStock" placeholder="Find Stock"
                class="p-2 border-box bg-[#1E2224] block w-full h-full text-[.9vw] text-gray-200 border border-[#7582783d] shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500"
                @keydown.up="up" @keydown.down="down" @keydown.esc="closeList" @keydown.enter="selectItem"
                v-model="selectedSymbol" @input="debounce(searchStock($event.target.value), 400)" />


              <svg @click.prevent="closeList" v-if="symbolList.length" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24" stroke-width="1.5" stroke="gray" class="cursor-pointer w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>

            </div>

            <symbol-list v-if="symbolList.length" :symbolInputName="selectedSymbol" :handleTop="scrollToItem"
              ref="symbolListRef" :symbol-list="symbolList" v-on:symbolSelected="symbolSelected" :isAbsolute="true"
              :selectedIndex="selectedIndex" :isChart="true" />
            <!--       
            <VueAutosuggest
                :suggestions="[{data:['Frodo', 'Samwise', 'Gandalf', 'Galadriel', 'Faramir', 'Éowyn']}]"
                :input-props="{id:'autosuggest__input', placeholder:'Do you feel lucky, punk?'}"
                @input="onInputChange"
                @selected="selectHandler"
                @click="clickHandler"
                >  
              <template slot-scope="{suggestion}">
                <span class="my-suggestion-item text-white">{{suggestion.item}}</span>
              </template>
</VueAutosuggest> -->


          </div>




          <!-- <span class="text-[#F8F9F9] font-bold text-[.8vw]">|</span>
          <div class="flex text-white w-fit h-[3vh] items-center justify-center"> -->
          <!-- <span class="text-[#F8F9F9] font-bold text-[.8vw]">Browse Stocks By Watchlists
            </span> -->
          <!-- </div> -->



          <div class="dropdown flex w-[12vw] h-[4vh] mt-1">
            <button
              class="bg-[#1E2224] dropdown-toggle w-[13vw] h-full text-[#9ea8af] border transition duration-150 ease-in-out border-[#7582783d] shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500"
              type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="flex justify-between w-full h-full p-2 border-box text-[.85vw]">
                <span> {{ selectedWatchListName }} </span>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-[1vw] h-[2vh]" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd" />
                </svg>
              </span>
            </button>

            <ul
              class="dd-menu dropdown-menu w-[12vw] absolute hidden h-[12vh] overflow-y-auto z-50 float-left list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none"
              aria-labelledby="dropdownMenuButton1">
              <li v-for="item in watchListApi_Data" :key="item.id"
                class="text-white hover:hover:bg-[#2E2E2F] bg-[#1E2224] text-sm p-1 cursor-pointer"
                @click="handleSelectedWatchList(item)">
                {{ item.name }} ({{ item.symbolList.length }})
              </li>
            </ul>
          </div>

          <div v-if="Object.keys(selectedWatchList).length" class="dropdown relative flex w-[12vw] h-[4vh] mt-1">
            <button
              class="bg-[#1E2224] dropdown-toggle w-[13vw] h-full text-[#9ea8af] border transition duration-150 ease-in-out border-[#7582783d] shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500"
              type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="flex justify-between w-full h-full p-2 border-box text-[.85vw]">
                <span>{{ selectedStockName }} </span>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-[1vw] h-[2vh]" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd" />
                </svg>
              </span>
            </button>

            <ul
              class="dd-menu dropdown-menu w-full absolute h-[12vh] overflow-y-auto z-50 float-left list-none cursor-pointer text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
              aria-labelledby="dropdownMenuButton1">
              <li v-for="(item, index) in selectedWatchList.symbolList" :key="index"
                class="text-white bg-black max-h-[36rem]" @click="handleSelectedStock(item)">
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="flex bg-[#151515]">
            <button class="text-white" @click="handleRefreshSelections">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="gray">
                <path fill-rule="evenodd"
                  d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                  clip-rule="evenodd" />
              </svg>
            </button>
          </div>
        </div>

        <div style="width: 80vw;" class="p-1 overflow-x-hidden flex items-center space-x-2">
          <div class="text-[12px] w-fit whitespace-nowrap px-1 text-[#F8F9F9]">Select Country:</div>
          <div class="flex w-11/12 overflow-x-auto items-center space-x-3">
            <div v-for="sym in breadcrumbSymbols" :class="symbolName == sym?.name ? 'bg-[#F8F9F9]' : ''"
              class="flex border rounded-[4px] w-20 pr-1  justify-between items-center">
              <span :title="sym.name" @click="loadChartBreadcrumb(sym)"
                :class="symbolName == sym?.name ? 'text-[#2E3238] font-semibold' : 'text-[#F8F9F9]'"
                class="w-9/12 overflow-x-hidden text-[12px] cursor-pointer text-center">{{ sym?.name }}</span>
              <span class="cursor-pointer w-2/12 h-full" title="remove" @click="handleBreadcrumbRemove(sym)">
                <!-- <svg width="10" height="10" viewBox="0 0 10 10" fill="none" class="" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.25 1.25L8.75 8.75M1.25 8.75L8.75 1.25" stroke="#2E3238" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg> -->
                <svg width="9" height="9" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.25 1.25L8.75 8.75M1.25 8.75L8.75 1.25"
                    :stroke="symbolName == sym?.name ? '#2E3238' : '#F8F9F9'" stroke-width="2.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
            </div>
          </div>
        </div>

        <div style="width: 70vw;">
          <chart-top-bar :isVisible="isVisible" @togglePriceLine="togglePriceLine" @getChartData="getChartData" :exchange="exchange"
            @togglePriceHighlight="togglePriceHighlight" @showChartModal="showChartModal"
            @toggleCrosshair="handleCrosshair" @toggleAutoYAxis="toggleAutoYAxis" @updateChartType="updateChartType"
            :auto_y_axis="auto_y_axis"></chart-top-bar>
        </div>

        <!-- <div class="flex h-full bg-[#151515]"> -->
        <div class="flex w-full">
          <!-- chart  -->

          <chart-side-bar gapSize="3.7rem"></chart-side-bar>
          <div class="flex-col h-fit">
            <ChartNew v-if="!chartLoading" :chart="computedChart" :width="chartWidth" :height="chartHeight + 60"
              :default-length="defaultDataLength" :title-txt="symbolCompanyName" @ChartLoad="ChartLoad"
              @updateYAxis="updateYAxis" :config="config" :enableCrosshair="crosshair" :applyShaders="priceHighlight" />
            <div class="w-[80vw] h-[90%] min-h-fit overflow-hidden" v-if="symbolCompanyName">
              <AreaChartLoader :showChartLoader="chartLoading" />
            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>
<script>
// import StockListDropDown from "@/components/popups/StockListDropDown";
import debounce from "@/helper/debounce";
import chartApi from "@/api/charts";
import SymbolList from "@/components/popups/SymbolList";
import ChartTopBar from "../vue_chart/ChartTopBar.vue";
import ChartBottomBar from "../vue_chart/ChartBottom.vue";
import ChartSideBar from "../vue_chart/ChartSideBar.vue";
import userApi from '@/api/userApi'
import { mapActions, mapGetters } from "vuex";
import ChartModal from "../vue_chart/ChartModal";
import ChartNew from "@/components/vue_chart/ChartNew";
import ChartEventNew from "@/components/vue_chart/chartEvent";
let chartEvents;
chartEvents = new ChartEventNew();
import SkeletonTableLoader from "@/components/loader/SkeletonTableLoader";
import DataCubeNew from "@/components/vue_chart/DataCube";
import AreaChartLoader from "@/components/loader/AreaChartLoader.vue";
import watchListApi from "@/api/watchListApi";
// import { VueAutosuggest } from 'vue-autosuggest';

export default {
  name: "ChartPage",
  components: {
    AreaChartLoader,
    SkeletonTableLoader,
    ChartNew,
    SymbolList,
    ChartTopBar,
    ChartBottomBar,
    ChartSideBar,
    // VueAutosuggest,
    // StockListDropDown,
    ChartModal,
  },
  data() {
    return {
      isVisible: false,
      breadcrumbSymbols: [],
      breadcrumbSelected: null,
      stockName: "",
      exchange: "ASX",
      wickUp: "#FFFFFF",
      showModal: false,
      dataCube: null,
      priceLine: true,
      crosshair: true,
      priceHighlight: true,
      auto_y_axis: true,
      legendDecimal: true,
      watchListApi_Data:{},
      selectedWatchList: {},
      selectedWatchListName: "Select Watchlist",
      selectedStockName: "Select Stock",
      selectedIndex: 0,
      xsett: {},
      symbolList: [],
      symbolNameInput: "tradin",
      chartWidth: window.innerWidth,
      chartHeight: window.innerHeight,
      selectedSymbol: "",
      config: {
        colorBack: "#151515",
        colorGrid: "#2c2929",
        colorCandleUp: "#00C42A",
        colorCandleDw: "#F30900",
        colorVolUp: "#1C5453",
        colorVolDw: "#762F33",
        colorWickUp: "#00C42A",
        colorWickDw: "#F30900",
        colorText: "white",
        indexBased: true,
        enableSideBarBoxValue: true,
        enableZoom: true,
        enableArrow: true,
        decimalPlace: 3,
        legendDecimal: true
      },

      itemHeight: 53,
      defaultRange: '1Y'
    };
  },
  created() {
    // this.char tHeight =this.chartPageContainer.clientHeight - this.$refs.searchContainer?.clientHeight -110;
  },
  async mounted() {

    try {
      const res = await watchListApi.fetchWatchListData();
      this.watchListApi_Data = res.data.payload;
    } catch (err) {
      console.log("something went wrong", err);
    }
    
    console.log("data mounted for chart")
    window.addEventListener("resize", this.onResize);
    // window.dc = this.chart;
    // this.$store.commit("WATCHLIST_API_DATA", res.data.data);

    this.chartWidth = this.chartPageContainer.clientWidth - 46;
    this.chartHeight =
      this.chartPageContainer.clientHeight -
      this.$refs.searchContainer?.clientHeight -
      110;

    //await this.fetchAllWatchListData()
    const sym = this.$route.query?.t ?? this.getSelectedSymbol;
    // this.$store.commit('SET_CHART_LOADING',true)
    // this.getChartData(sym, this.getTimeFrame).then((d) => {
    //   // this.$store.commit('SET_CHART_LOADING',false)
    //   // console.log("d")
    //   if(d){
    //     // alert('updated maybe')
    //     // this.processOHLCV(this.ohlcv)
    //   }
    //   // chartEvents.$emit('setChartRange',undefined)
    // });

    await this.getSymbolNameFromUrl()
    await this.handleGetBreadcrumbSymbols(sym)

    // this.chartData.merge(".settings")
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    ...mapActions(["fetchAllWatchListData", "setSymbolData", "setNewChartType"]),
    ChartLoad(compRef, dataCube) {
      // console.log("ChartLoad")

      this.chartLoading = false;
      // console.log("ChartLoad",compRef,compRef.overlays,dataCube)
    },

    async getSymbolNameFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      const val = urlParams.get('t');
      if (val) {
        await chartApi.getListOfSymbolsStartWith(val).then(async(res) => {
          let recData = res[0]
          if (recData?.name) {
            await this.handleBreadcrumbAdd(recData)
            this.breadcrumbSelected = recData?.name
          }
        });
      }
    },

    async handleBreadcrumbAdd(item) {
      const res = await userApi.getBreadcrumbList();
      if (res?.data?.success) {
        this.breadcrumbSymbols = res?.data?.payload;
      }
      const isNameAlreadyPresent = this.breadcrumbSymbols?.some(val => val?.name === item?.name);
      if (!isNameAlreadyPresent) {
        this.breadcrumbSymbols.push(item);
        this.breadcrumbSelected = item?.name
        if (this.breadcrumbSymbols?.length > 15) {
          this.breadcrumbSymbols.shift();
        }
        try {
          const payload = {
            data: this.breadcrumbSymbols
          }
          const res = await userApi.addBreadcrumbList(payload);
          if (res?.data?.success) {
            console.log("saved")
          }
          console.log("breadCrum==>", res)
        } catch (err) {
          console.log("err==>", err)
        }
      }

    },
    async loadChartBreadcrumb(value) {
      this.breadcrumbSelected = value.name
      this.selectedSymbol = ""
      this.symbolList = [];
      document.getElementById("findStock").value = "";
      this.$store.commit('SET_CHART_LOADING', true)
      await this.getChartData(value.name, this.getTimeFrame, value.id);
      this.$store.dispatch("updateChartConfig", {
        selectedSymbol: value.name
      })
    },
    async handleGetBreadcrumbSymbols(sym) {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const val = urlParams.get('t');
        const res = await userApi.getBreadcrumbList();
        if (res?.data?.success) {
          this.breadcrumbSymbols = val ? this.breadcrumbSymbols : res?.data?.payload
          this.breadcrumbSymbols =  res?.data?.payload
          let last =this.breadcrumbSymbols.filter(x => x.name == sym)[0]
          if (last) {
            this.loadChartBreadcrumb(last)
          }
        } else {
          this.breadcrumbSymbols = []
        }
      } catch (err) {
        console.log("error", err)
      }
    },

    async handleBreadcrumbRemove(item) {
      let updatedArr = this.breadcrumbSymbols?.filter(val => val?.name !== item?.name);
      this.breadcrumbSymbols = updatedArr
      let last = this.breadcrumbSymbols[this.breadcrumbSymbols?.length - 1]
      if (last) {
        this.loadChartBreadcrumb(last)
      }
      console.log("remove arr", this.breadcrumbSymbols)
      try {
        const payload = {
          data: this.breadcrumbSymbols
        }
        const res = await userApi.addBreadcrumbList(payload);
        if (res?.data?.success) {
          console.log("saved")

        }
        console.log("breadCrum==>", res)
      } catch (err) {
        console.log("err", err)
      }
    },

    handleSelectedSubTrendValue(value) {
      console.log(value);
      this.HideModal()
    },


    handleFocusOut() {
      this.symbolList = []

    },

    up() {
      if (this.selectedIndex == 0) {
        return;
      }
      this.selectedIndex -= 1
      this.scrollToItem()
      console.log("up =>", this.selectedIndex)
    },
    togglePriceLine(value) {
      this.priceLine = value;
      let chartSetting = this.chart.get_one("chart.settings")
      console.log("chart data", chartSetting)
      this.chart.merge("chart.settings", {
        priceLine: value
      })

    },
    togglePriceHighlight(value) {
      this.priceHighlight = value;
    },

    toggleAutoYAxis() {
      this.auto_y_axis = !this.auto_y_axis
    },
    updateYAxis(value) {
      console.log("updateYAxis auto_y_axis", value)
      this.auto_y_axis = value
    },
    handleCrosshair(value) {
      this.crosshair = value
      // let chartSetting = this.chart.get_one("chart.settings")
      // console.log("chart data",chartSetting)
      // this.chart.merge("chart.settings",{
      //   enableCrosshair:value
      // })
      // console.log("crosshair",value)
    },

    down() {
      if (this.selectedIndex >= this.symbolList.length - 1) {
        return;
      }
      this.selectedIndex += 1
      this.scrollToItem()
      console.log("down =>", this.selectedIndex)
    },

    scrollToItem() {
      console.log('this.$refs.symbolListRef', this.$refs.symbolListRef.$refs.optionsList)
      if (this.$refs.symbolListRef && this.$refs.symbolListRef.$refs.optionsList)
        this.$refs.symbolListRef.$refs.optionsList.scrollTop = this.selectedIndex * this.itemHeight
    },

    selectItem() {
      let data = this.symbolList[this.selectedIndex]
      this.selectedSymbol = data.name
      this.symbolSelected(data)
      this.selectedIndex = 0
      this.symbolList = []
      this.exchange = data.id
    },

    closeList() {
      this.symbolList = []
    },



    showChartModal() {
      this.showModal = true;
    },
    HideModal() {
      this.showModal = false;
    },
    afterChartLoad() {
      setTimeout(() => {
        if (this.showChartVolume) {
          chartEvents.$emit('ADD_INDICATOR', "VolumePrimary")
        }
        if (this.getSelectedIndicators && this.getSelectedIndicators.length) {
          for (const selectedIndicator of this.getSelectedIndicators) {
            // console.log("selectedIndicator",selectedIndicator)
            chartEvents.$emit('ADD_INDICATOR', selectedIndicator)
          }
        }
        // context.T
      })

    },
    async updateChartType(item) {
      this.setNewChartType(item);
      await this.getChartData(this.getSelectedSymbol, this.getTimeFrame, this.exchange, this.ohlcv, this.ohlcvTimeMap);
      // this.$store.commit('SET_CHART_LOADING',true)

      // this.getChartData(value.name, this.getTimeFrame,value.id)
      // this.$nextTick(() => {
      //   this.$store.commit('SET_CHART_LOADING',false)
      //   this.afterChartLoad()
      // })
    },
    async getChartData(symbol, timeFrame, exchange, dataMap = [], timeStampMap = []) {
      let chartRange = localStorage.getItem("TimeRange")
      this.$store.commit('SET_CHART_LOADING', true)
      await this.setSymbolData({ symbolName: symbol, timeFrame: timeFrame, exchange, dataMap, timeStampMap })
      this.$store.commit('SET_CHART_LOADING', false)
      this.afterChartLoad()

      return true
      // setTimeout(() => {
      //   if(rangeParse?.range){
      //     chartEvents.$emit('setChartRange',rangeParse?.range)
      //   }else{
      //     chartEvents.$emit('setChartRange',this.defaultRange)
      //   }
      // })

    },
    handleRefreshSelections() {
      this.selectedWatchListName = "Select Watchlist";
      this.selectedStockName = "Select Stock";
      this.selectedWatchList = {}
    },

    handleSelectedWatchList(value) {
      this.selectedWatchList = value;
      this.selectedWatchListName = value.name;
      this.selectedStockName = "Select Stock";
    },
    async handleSelectedStock(value) {
      // console.log('handleSelectedStock',value)
      this.handleBreadcrumbAdd(value)
      this.selectedStockName = value?.name;
      // this.$store.commit('SET_CHART_LOADING', true)
      this.getChartData(value?.name, this.getTimeFrame).then(() => {
        // this.$store.commit('SET_CHART_LOADING', false)
        // chartEvents.$emit('setChartRange',undefined)
      });
    },

    async symbolSelected(value, index) {
      console.log("selected symbol", JSON.stringify(value));
      this.handleBreadcrumbAdd(value)
      this.selectedSymbol = value.name
      this.breadcrumbSelected = value.name
      this.selectedIndex = index
      this.symbolList = [];
      document.getElementById("findStock").value = "";
      // this.$store.commit('SET_CHART_LOADING', true)
      await this.getChartData(value.name, this.getTimeFrame, value.id);
      this.$store.dispatch("updateChartConfig", {
        selectedSymbol: this.selectedSymbol
      })
    },
    debounce,
    searchStock(value) {
      value = value.trim();

      // Remove multiple spaces between words and keep only the last space
      value = value.replace(/\s+/g, ' ').trim();

      this.stockName = value

      //due to $ issue
      // if (value && value.length && this.symbolNameInput !== value) {
      if (value && value.length) {
        console.log("key up", value, this.symbolNameInput)
        chartApi.getListOfSymbolsStartWith(value).then((res) => {
          this.symbolNameInput = value;
          this.symbolList = res;
        });
      }
      if (value.length === 0) {
        this.symbolList = [];
      }
    },

    onResize() {

      this.chartWidth = this.chartPageContainer.clientWidth - 46;
      this.chartHeight =
        this.chartPageContainer.clientHeight -
        this.$refs.searchContainer?.clientHeight -
        110;
      // this.chartHeight = 0
      this.$nextTick(() => {
        this.chartWidth = this.chartPageContainer.clientWidth - 46;
        this.chartHeight =
          this.chartPageContainer.clientHeight -
          this.$refs.searchContainer?.clientHeight -
          110;
      });
    },
    findClosestTimestampIndex(timestamp, dataset) {
      let closestIndex = 0;
      let minDiff = Math.abs(timestamp - dataset[0]);

      for (let i = 1; i < dataset.length; i++) {
        const diff = Math.abs(timestamp - dataset[i]);
        if (diff < minDiff) {
          minDiff = diff;
          closestIndex = i;
        }
      }

      return closestIndex;
    },
    async setChartData(data) {
      let chartData = this.chart.get('chart')
      let selectedTimeFrame = `1${this.getTimeFrame}`;
      console.log("selectedTimeFrame", selectedTimeFrame)
      // this.chart.set('chart.tf',selectedTimeFrame)
      // await this.$nextTick()
      console.log("set chart data ....", data)
      // this.chart.set('chart.data',data)
      this.chart.merge('chart', {
        ...chartData, data: data, tf: `1${this.getTimeFrame}`
      })
      await this.$nextTick()
      setTimeout(async () => {

      })
    },
  },
  updated() {
    // console.log('component update', this.refs)
  },
  computed: {
    ...mapGetters(["ohlcvTimeMapPnF", "ohlcvPnF", "getSelectedSymbol", "chartStartingIndex", "chartEndingIndex", "defaultDataLength", "chartStartingTime", "getTimeFrame", "getChartType", "ohlcvTimeMap", "chartVisibleRange", "dc", "symbolName", "symbolCompanyName", "chartLoading", "ohlcv", "showChartVolume", "getSelectedIndicators", "getTimeFrame", "tv"]),
    chartPageContainer() {
      return this.$refs.chartPageContainer;
    },
    chartContainerNew() {
      return this.$refs.chartContainerNew;
    },
    chart() {
      return this.dc || this.chartData;
    },
    computedChart() {
      let chartType = this.getChartType;
      let isPnf = chartType === "PnF";
      let initRange = [this.chartStartingIndex, this.chartEndingIndex]
      if (isPnf) {
        let dataMapLength = this.ohlcvTimeMapPnF.length;
        initRange = [dataMapLength - 30, dataMapLength + 5]
      }
      let props = isPnf ? {
        tf: `1${this.getTimeFrame}`,
        type: chartType,
        data: this.ohlcvPnF,
        ohlcvTimeMap: this.ohlcvTimeMapPnF,
        settings: {
          noidea: false
        }
        // initRange: [0, 10]
      } : {
        tf: `1${this.getTimeFrame}`,
        type: chartType,
        data: this.ohlcv,
        ohlcvTimeMap: this.ohlcvTimeMap,
        initRange: initRange
      };
      console.log("computedChart", props)
      return new DataCubeNew(props)
    }
  },

  watch: {
    stockName: function (val) {
      if (!val) {
        this.symbolList = [];
      }
    },
    showChartVolume(n) {
      if (n) {
        chartEvents.$emit("ADD_INDICATOR", "VolumePrimary")
      } else {
        chartEvents.$emit("REMOVE_INDICATOR", "VolumePrimary")
      }
    },
    ohlcv(data) {
      // processOHLCV(data)
    }
  }
};
</script>
<style>
.trading-vue-legend {
  z-index: 1 !important;
}
</style>
<style scoped>
.overlay__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /*margin-top: 30vh;*/
  /*transform: translate(750%, -10%);*/
}

.spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

</style>
