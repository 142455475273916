<script>
import { Overlay, Tool } from 'trading-vue-js'
import Pin from './primitives/pin.js'
import FixedPinX from './primitives/fixedPinX.js'
import Seg from './primitives/seg.js'
import Ray from './primitives/ray.js'
import { mapActions, mapGetters } from 'vuex'

const HIGH = 2
const LOW = 3

export default {
  name: 'TrailingStopTool',
  mixins: [Overlay, Tool],
    beforeUnmount() {
    this.resetTrailingStop()
  },
  methods: {
    ...mapActions(['updateTrailingStop', 'resetTrailingStop']),
    meta_info() {
      return { author: 'LakshanPerera', version: '1.0.0' }
    },
    tool() {
      return {
        // Descriptor for the tool
        group: 'Measurements',
        type: 'PL',
        hint: 'Price Range',
        data: [], // Default data
        settings: { price: true, time: true } // Default settings
      }
    },
    // Called after overlay mounted
    init() {
      this.$emit('object-selected', false)

      //--- dispatch action to show the dialog box
      this.updateTrailingStop({ key: 'isDialogBoxOpened', data: true })
      this.updateTrailingStop({
        key: 'entryPrice',
        data: this.mouse.y$.toFixed(3)
      })
      this.updateTrailingStop({ key: 'isOnChart', data: true })

      this.stepValue = this.getTrailingStop.stepPrice
      // First pin is settled at the mouse position
      this.pins.push(
        new Pin(this, 'p1', {
          hidden: this.shift
        })
      )
      // Second one is following mouse until it clicks
      this.pins.push(
        new FixedPinX(this, 'p2', {
          state: 'settled',
          hidden: this.shift,
          isFixedPin: true,
          fixedX: this.pins[0].x,
          fixedTime: this.pins[0].t
        })
      )

      this.pins[1].y$ =
        parseFloat(this.getTrailingStop.entryPrice) -
        this.getTrailingStop.entryPrice * 0.01
      this.pins[1].y = this.$props.layout.$2screen(this.pins[1].y$)
      this.pins[1].x = this.pins[0].x
      this.pins[1].t = this.pins[0].t

      this.pins[1].update_settings('p2', this.$props.cursor.t, this.pins[1].y$)

      // end drawing mode
      this.set_state('finished')
      this.$emit('drawing-mode-off')
      // Deselect the tool in shiftMode
      if (this.shift) {
        this._$emit('custom-event', {
          event: 'object-selected',
          args: []
        })
      }

      this.tGap =
        this.$props.layout.candles[1].raw[0] -
        this.$props.layout.candles[0].raw[0]
    },
    draw(ctx) {
      if (!this.p1 || !this.p2) return
      const layout = this.$props.layout
      ctx.lineWidth = this.line_width
      ctx.strokeStyle = this.color
      // Background
      ctx.globalAlpha = 1
      // let x1 = layout.t2screen(this.p1[0]);
      // let y1 = layout.$2screen(this.p1[1]);
      // let x2 = layout.t2screen(this.p2[0]);
      // let y2 = layout.$2screen(this.p2[1]);

      // ctx.fillStyle = "#23a776";
      // ctx.globalAlpha = 0.1;

      // ctx.fillRect(x1, y1, x2 - x1, y2 - y1);

      ctx.beginPath()
      ctx.strokeStyle = '#23a776'
      this.pins[1].t = this.p1[0]
      new Ray(this, ctx).draw(this.p1, [this.p1[0] + this.tGap, this.p1[1]])
      ctx.stroke()

      // ctx.beginPath();
      // ctx.strokeStyle = "#e54150";
      // new Ray(this, ctx).draw(
      //   [this.p1[0], this.p2[1]],
      //   [this.p1[0] + this.tGap, this.p2[1]]
      // );
      // ctx.stroke();

      ctx.globalAlpha = 0
      new Seg(this, ctx).draw(this.p1, this.p2)
      ctx.stroke()
      ctx.globalAlpha = 1

      ctx.beginPath()
      ctx.strokeStyle = 'red'
      const entryPrice = this.p1[1]
      let lastHighestPoint = 0,
        lastSLPosition = this.p2[1],
        lastPosition = null
      let first = true,
        slChanged = false
      for (const candle of this.$props.layout.candles) {
        if (candle.raw[0] < this.p1[0]) continue

        if (first) {
          ctx.moveTo(candle.x, layout.$2screen(lastSLPosition))
          ctx.lineTo(candle.x + candle.w, layout.$2screen(lastSLPosition))
          lastSLPosition += this.stepValue
          first = false
          continue
        }

        if (candle.raw[HIGH] > lastHighestPoint) {
          lastHighestPoint = candle.raw[HIGH]
          lastSLPosition += this.stepValue
        }
        if (!slChanged && lastSLPosition > entryPrice && lastPosition) {
          ctx.stroke()
          ctx.beginPath()
          ctx.strokeStyle = 'green'
          ctx.moveTo(lastPosition.x, lastPosition.y)
        }
        ctx.lineTo(candle.x, layout.$2screen(lastSLPosition))
        ctx.lineTo(candle.x + candle.w, layout.$2screen(lastSLPosition))
        lastPosition = {
          x: candle.x + candle.w,
          y: layout.$2screen(lastSLPosition)
        }

        //--- check for SL hits
        if (candle.raw[LOW] < lastSLPosition) break
      }
      ctx.stroke()

      this.render_pins(ctx)
    },
    use_for() {
      return ['TrailingStopTool']
    },
    data_colors() {
      return [this.color]
    }
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters(['getTrailingStop','themePrimaryColor','themeSecondaryColor','themeFontColor']),
    sett() {
      return this.$props.settings
    },
    p1() {
      return this.$props.settings.p1
    },
    p2() {
      return this.$props.settings.p2
    },
    line_width() {
      return this.sett.lineWidth || 2
    },
    color() {
      return this.sett.color || this.$props.colors.cross
    },
    back_color() {
      return this.sett.backColor || '#9b9ba316'
    },
    value_back() {
      return this.sett.valueBack || '#9b9ba316'
    },
    value_color() {
      return this.sett.valueColor || this.$props.colors.text
    },
    prec() {
      return this.sett.precision || 2
    },
    new_font() {
      return '12px ' + this.$props.font.split('px').pop()
    },
    price() {
      return 'price' in this.sett ? this.sett.price : true
    },
    time() {
      return 'time' in this.sett ? this.sett.time : false
    },
    shift() {
      return this.sett.shiftMode
    }
  },
  watch: {
    getTrailingStop(newValue) {
      this.stepValue = parseFloat(this.getTrailingStop.stepPrice)
      if (!this.pins[0]) return
      this.pins[0].y$ = parseFloat(newValue.entryPrice)
      this.pins[0].y = this.$props.layout.$2screen(this.pins[0].y$)
      this.$emit('change-settings', {
        p1: [this.p1[0], this.pins[0].y$]
      })

      if (!this.pins[1] || !newValue.stopPrice) return
      this.pins[1].y$ = parseFloat(newValue.stopPrice)
      this.pins[1].y = this.$props.layout.$2screen(
        parseFloat(newValue.stopPrice)
      )
      this.pins[1].update_settings(
        'p2',
        this.p1[0],
        parseFloat(newValue.stopPrice)
      )
    }
  },
  data() {
    return {}
  }
}
</script>
