<script>
// Renedrer for ohlcbar + volume (optional)
// It can be used as the main chart or an indicator
import TCI, {ETrend} from '../indicators/calculators/tci'
import priceline from './priceline.js'
import {Overlay} from 'trading-vue-js'
import {mapGetters} from "vuex";
import moment from 'moment'
import XOhlcBarTrend from './primitives/xohlcbartrend'

export default {
  name: 'BarTrend',
  mixins: [Overlay,priceline],
  methods: {
    meta_info() {
      return {
        author: 'LakshanPerera',
        version: '1.3.0',
        desc: 'Bar Trend',
        preset: {
          name: 'BarTrend',
          side: 'onchart'
        }
      }
    },
    init() {
      this.priceInit()
      this.sma = []
      this.period = this.barTrendChart
      this.barSymbol = this.symbolName
      if (this.dc) {
        console.log("print data",this.dc.data.chart.tf)
        this.timeFrame = this.dc.data.chart.tf
        this.wholeData = this.dc.data.chart.data
        this.wholeData.forEach((data, index) => {
          let sum = 0
          for (let i = index; i > Math.max(0, index - this.period); i--) {
            sum += this.wholeData[i][4]
          }
          this.sma[data[0]] = sum / this.period
        })


        this.tci = new TCI()
        const _data= this.wholeData.map(arr => (
          {
            format: moment(arr[0]).format("yyyy-MM-DD"),
            date: arr[0],
            open: arr[1],
            high: arr[2],
            low: arr[3],
            close: arr[4],
            volume: arr[5]
          }
        )).filter(d => !isNaN(d.high)|| !isNaN(d.low))
        this.tci.Calculate(
          _data,
          _data.length - 1,
          this.period
        )
        // console.log('tci====>', this.tci.Turns)
      }
    },
    draw(ctx) {
      //console.log("XOhlcBar.draw() called. this.$props.data.length = " + this.$props.data.length)
      // render as main chart:
      let currentChartTimeFrame = this.dc.data.chart.tf;
      let wholeDataLength = this.wholeData.length;
      if (
        this.dc &&
        (currentChartTimeFrame !== this.timeFrame
          || wholeDataLength !== this.getOhlcv.length
          || this.symbolName !== this.barSymbol
        )
      ) {
        // console.log("this.dc",this.dc)
        this.init()
      }
      // console.log("tci====> Data Sum",{      wholeDataLen:this.wholeData.length,        getOhlcvLen:this.getOhlcv.length,      })
      if (this.$props.sub === this.$props.data) {
        var cnv = {
          ohlcbars: this.$props.layout.candles,
          volume: this.$props.layout.volume
        }
      }

      // if (this.show_volume) {
      //   for (let i = 0; i < cnv.volume.length; i++) {
      //     let volumeData = cnv.volume[i]
      //     let barData = cnv.ohlcbars[i]
      //     new XVolbar(this, ctx, volumeData, barData)
      //   }
      // }

      const layout = this.$props.layout

      //--- always set to false
      if (this.show_sma) {
        console.log(ctx.strokeStyle, this.color)
        ctx.strokeStyle = 'green'
        ctx.lineWidth = this.line_width * 3
        ctx.strokeStyle = this.color
        ctx.moveTo(0, 0)
        ctx.beginPath()
        for (const key in this.sma) {
          const x = layout.t2screen(key)
          const y = layout.$2screen(this.sma[key])
          ctx.lineTo(x, y)
        }

        ctx.stroke()
      }
      for (const i in cnv.ohlcbars) {
        let ohlcbar = cnv.ohlcbars[i]
        // console.log("ohlcbar",ohlcbar,this.tci.Trend)
        let barData = this.tci.Trend[ohlcbar?.raw?.[0]]

        let color = '#6b6868'
        if(barData !== null){
          if (ETrend.Down === barData) {
            color = this.downBarColor //"#e54150"
          } else if (ETrend.Up === barData) {
            color = this.upBarColor //'#23a776'
          }
        }
        new XOhlcBarTrend(this, ctx, ohlcbar, color)
        }

        //new XOhlcBar(this, ctx, barData, color)


      if (this?.price_line) this.price.draw(ctx);
    },
    use_for() {
      return ['BarTrend']
    },
    // When added as offchart overlay
    y_range() {
      return [
        Math.max(...this.$props.sub.map(x => x[2])),
        Math.min(...this.$props.sub.map(x => x[3]))
      ]
    }
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters(['getGeneralUserSettings',"dc","symbolName","getSymbolData","barTrendChart"]),
    sett() {
      return this.$props.settings
    },
    getTCI(){
      return this.tci
    },
    getOhlcv() {
      return this.getSymbolData
    },
    show_volume() {
      return false
    },
    upVolBarColor() {
      return this.sett.upBarColor || '#23a77655'
    },
    downVolBarColor() {
      return this.sett.downBarColor || '#e5415055'
    },
    volBarWidth() {
      return this.sett.barWidth || 2
    },
    upBarColor() {
      let upBarColorStr = '#23a776'
      // if(this.getGeneralUserSettings){
      //   if(this.getGeneralUserSettings.chart['upColor'] && this.getGeneralUserSettings.chart['upColor']['value']) {
      //     upBarColorStr = this.getGeneralUserSettings.chart['upColor']['value']
      //   }
      // }
      return upBarColorStr
    },
    downBarColor() {
      let downBarColorStr = "#e54150";
      // console.log("Bar Change",this.getGeneralUserSettings.chart['barChange'] && this.getGeneralUserSettings.chart['barChange']['value'])
      // if(this.getGeneralUserSettings){
      //   if(this.getGeneralUserSettings.chart['downColor'] && this.getGeneralUserSettings.chart['downColor']['value']){
      //     downBarColorStr =  this.getGeneralUserSettings.chart['downColor']['value']
      //   }
      // }
      return downBarColorStr
    },
    barWidth() {
      return this.sett.barWidth || 1
    },
    hlcOnly() {
      return this.sett.hlcOnly || false
    },
    lineWidth() {
      return this.sett.lineWidth || 1
    }
  },
  data() {
    return { timeFrame: 'D'
    }
  },
  watch:{
    barTrendChart(n){
      console.log("period barTrendChart",n)
      this.init()
    }
  }
}
</script>
