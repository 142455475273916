<template>
  <window :title="data.ov.desc" class="sett-win" :tv="tv" @close="on_close">
    <div v-for="k in settlist" class="sett-win-item" :key="k">
      <label>{{ s2d(k) == 'Param K' ? '% K' : s2d(k) == 'Param D' ? '% D' : s2d(k) == 'Period' ? 'Days' : s2d(k)}}</label>
      <std-input
        :ref="getId('fi', 'set', k)"
        :value="sett[k]"
        @input="update_sett(k, $event)"
        :type="getType(k)"
        :step="getStep(k)"
        :label="s2d(k)"
      />
    </div>

    <div v-for="k in styleSettings" class="sett-win-item" :key="k">
      <label>{{s2d(k) == 'Color' ? 'Line Color' : s2d(k) }}</label>
      <std-input
        :ref="getId('fi', 'set2', k)"
        :value="sett[k]"
        @input="update_sett(k, $event)"
        :type="getType(k)"
        :step="getStep()"
      />
    </div>
    <span
      v-if="!settlist.length && !styleSettings.length"
      class="sett-win-empty" 
    >
      <i>No settings</i>
    </span>
  </window>
</template>
<script>
import Window from "../shared/Window.vue";
import StdInput from "../shared/StdInput.vue";
import Utils from "./utils.js";
import debounce from "@/helper/debounce";
import { mapGetters } from "vuex";
export default {
  name: "SettingsWin",
  components: { Window, StdInput },
  props: ["id", "main", "dc", "tv", "data"],
  computed: {
    ...mapGetters(["ohlcvTimeMapPnF", "ohlcvPnF", "getSelectedSymbol", "chartStartingIndex", "chartEndingIndex", "defaultDataLength", "chartStartingTime", "getTimeFrame", "getChartType", "ohlcvTimeMap", "watchListApi_Data", "chartVisibleRange", "dc", "symbolName", "symbolCompanyName", "chartLoading", "ohlcv", "showChartVolume", "getSelectedIndicators", "getTimeFrame", "tv"]),
    sett() {

      let settings = this.$props.data.ov.settings;
      console.log("Main Extension Data settings===>", settings)
      return settings;
    },
    settingsLabelMapComputed() {
      let settingsLabelMapProps = this.$props.data.ov.settingsLabelMap;
      console.log("Main Extension Data settings", settingsLabelMapProps)
      return settingsLabelMapProps ? settingsLabelMapProps : {};
    },
    settlist() {

      return Object.keys(this.sett).filter(
        (x) => x[0] !== "$" && this.sett.$props && this.sett.$props.includes(x)
      );
    },
    styleSettings() {
      return Object.keys(this.sett).filter(
        (x) => x[0] !== "$" && x.match(/(color|width|lineType)/i) != null
      );
    },
  },
  mounted() {

    console.log("setting windows Store", this.symbolName);
    console.log("setting windows main", this.$props.main);
    console.log("setting windows id", this.$props.id);
    console.log("setting windows dc", this.$props.dc);
    console.log("setting windows tv", this.$props.tv);
    console.log("setting windows data", this.$props);
    // console.log("setting windows title", this.$props.data.ov);
  },
  created() {
    // console.log("setting windows created", this.$props.dc);
  },
  updated() {
    // console.log("setting windows updated", this.$props.tv);
  },
  methods: {
    getId(name, type, id) {
      return `${name}-${type}-${id}`;
    },
    getType(field) {
      if (field.match(/color/i) != null) return "color";
      if (field.match(/lineType/i) != null) return "select-linetype";
      if (field.match(/(period|length|width)/i) != null) return "number";
      return "number";
    },
    getStep() {
      // if (field.match(/width/i) != null) return 0.25
      return 1;
    },
    s2d(str) {
      return Utils.sett2desc(str,this.settingsLabelMapComputed);
    },
    on_close() {
      console.log("remove")
      this.$props.main.remove_widget(this.$props.id);
    },
    update_sett(k, val) {
      console.log("navedata==>", k, val, this.symbolName)
      console.log("valN", this.$props.data.ov.settings)
      //PRESEVE THE SETTINGS OF INDICATOR SETTINGS
      let storedSettings = localStorage.getItem('indicators_settings');
      let settingsArray = storedSettings ? JSON.parse(storedSettings) : [];
      const parts = this.$props.data.ov.settings.$uuid.split('-');

// Retrieve the first value
      const symbolTypeName = parts[0];
      // Check if settings for the current symbol already exist
      let symbolSettingsIndex = settingsArray.findIndex(item => item.symbolname === this.symbolName && item.uuid===symbolTypeName);
      // Create or update settings for the current symbol
      let settings = {
        uuid:symbolTypeName,
        symbolname: this.symbolName,
        [k]: val
      };
      if (symbolSettingsIndex !== -1) {
        // If settings for the current symbol exist, update them
        Object.assign(settingsArray[symbolSettingsIndex], settings);
      } else {
        // If settings for the current symbol don't exist, push them
        settingsArray.push(settings);
      }
      // Save updated settings back to local storage
      localStorage.setItem('indicators_settings', JSON.stringify(settingsArray));
      //ENDS-PRESEVE THE SETTINGS OF INDICATOR SETTINGS

      let dcid = this.$props.data.ov.id;
      let valN = parseFloat(val);
     
      if (valN !== valN) valN = val;
      // console.log(this.$props.dc.get_one("chart.settings"))
      this.$props.dc.merge(`${dcid}.settings`, { [k]: valN });
      if (!val?.includes("#")) {
        setTimeout(() => {
          this.$props.dc.tv.resetChart(true)
        })
      }

    },
  },
};
</script>
<style scoped>
.tvjs-x-window.sett-win {
  padding-bottom: 30px;
  border: 1px solid rgba(2, 189, 45, 0.3);
}
.sett-win-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}
.sett-win-item label {
  min-width: 80px;
  font-family: Trebuchet MS, roboto, ubuntu, sans-serif;
  color: rgb(182, 182, 182);
}
.sett-win-empty {
  opacity: 0.5;
}
</style>
