<script>
import { getIndicatorSettings } from '@/extensions/indicatorSettings/defaultIndicatorSettings';
import { Overlay } from 'trading-vue-js'
export default {
  name: 'WMA',
  mixins: [Overlay],
  methods: {
    meta_info() {
      const indicatorName = 'WMA';
      const { period, lineWidth, lineType, color } = getIndicatorSettings(indicatorName);
      return {
        author: 'StdSquad',
        version: '1.0.0',
        desc: 'Weighted Moving Average',
        preset: {
          name: 'WMA',
          side: 'onchart',
          settings: {
            lineWidth,
            color,
            lineType,
            period
            
          }
        }
      }
    },
    use_for() {
      return ['WMA']
    },
    calc() {
      return {
        props: {
          //period: { def: 12, text: 'Period' }
          period: { def: this.meta_info().preset.settings.period, text: 'Period' }
        },
        conf: { renderer: 'Spline' },
        update: `
                    return wma(close, period)[0]
                `
      }
    }
  }
}
</script>