<template>
  <trading-vue
      ref="chartContainer"
      id="chartContainer"
      :overlays="overlays"
      :x-settings="xsett"
      :extensions="extensions"
      v-on:sidebar-transform="sidebar_transform"
      v-on:range-changed="range_changed"
      v-on:chart_data_changed="chart_data_changed"
      :indexBased="selectedDataType"
      :data="chart"
      :width="width"
      :height="height"

      :title-txt="titleTxt"

      v-bind="config"
      :chartConfig="{
        DEFAULT_LEN:defaultLength
      }"
      :legend-buttons="['display', 'settings', 'remove']"
      :enableCrosshair="enableCrosshair"
      :applyShaders="applyShaders"
  ></trading-vue>
</template>
<script>
import Overlays from "@/overlays";
import Extensions from "@/extensions";
import TradingVue from "trading-vue-js";
import ChartEventNew, {GetIterationData, GetTimeRange} from "@/components/vue_chart/chartEvent";
// import moment from "moment/moment";
import moment  from "moment";
import {mapGetters} from "vuex";
import DataCubeNew from "@/components/vue_chart/DataCube";

let chartEvents;
chartEvents = new ChartEventNew();
export default {
  name:"ChartNew",
  props:{
    chart: {
      type: Object,
      default() {
        return new DataCubeNew()
      }
    },
    config:{
      type: [Object],
      default() {
        return {}
      }
    },
    defaultLength:{
      type:Number,
      default:100
    },
    titleTxt:{type:String},
    enableCrosshair:{type:Boolean,default:true},
    applyShaders:{type:Boolean,default:true},
    height:{type:Number},
    width:{type:Number},
  },
  components:{
    TradingVue
  },
  methods:{
    chart_data_changed(flag){
      if(flag){
        // verify range here may be
        // console.log("setChartRange Library For Data Update",flag)
      }
    },
    range_changed(r, manualInteraction) {

      console.log("range_changed CallBack From Library", r)

      if (!isNaN(r[0]) && !isNaN(r[1])) {
        if (manualInteraction) {
          this.$store.commit("setChartStartingTime", r[0])
          this.$store.commit("setChartEndingTime", r[1])
          this.$store.commit("SET_CHART_MANUAL_INTERACTION", true)
        }
        this.$store.commit('DEFINE_RANGE_IN_DATE', r)
      }

    },
    sidebar_transform(yTransform) {
      console.log("yTransform", yTransform)
      this.$emit('updateYAxis', yTransform.auto)
    },
    setChartRange(range, event, callback) {
      const $store = this.$store;
      const state = $store.state;


      let rangeObj = { range: range ? range : state.chartVisibleRange, start: null, end: null }
      /**
       * isRangeChange shows whether the user clicked on 3M,6M,YTD,1Y,5Y... (buttons on the top)
       */
      let isRangeChange = rangeObj.range !== state.chartVisibleRange
      if (isRangeChange) {
        let eventStr = JSON.stringify(rangeObj)
        localStorage.setItem('TimeRange', eventStr)
      }
      let hasStoreRange = localStorage.getItem('TimeRange')
      if (hasStoreRange) {
        rangeObj = JSON.parse(hasStoreRange)
      }
      const candleData = this.chart.get_one('chart.data')
      // console.log("CompareChart setChartRange",{event,range,hasStoreRange,chartVisibleRange:state.chartVisibleRange,dataLen:candleData.length})
      setTimeout(() => {
        let ti_map_config = this.tv.$refs?.chart?.ti_map
        if (candleData.length) {
          $store.commit('CHART_VISIBLE_RANGE', rangeObj.range)
          // this.chart.merge('chart.tf', `1${this.getTimeFrame}`)
          if (
            (isRangeChange)
          ) {
            state.chartVisibleRange = range
            let [startingTime, endingTime] = GetTimeRange(candleData, rangeObj.range, state.timeFrame)
            // endingTime = lastCandleTimeObj.valueOf()
            let timeMap = [
              this.selectedDataType ? ti_map_config.t2i(startingTime) : startingTime,
              this.selectedDataType ? ti_map_config.t2i(endingTime) : endingTime
            ]
            console.log({
              startingTime,
              startingTimeId: timeMap[0],
              // startingTimeStr:startingTimeObj.format("DD-MM-YYYY"),
              endingTime,
              endingTimeId: timeMap[1],
              // endingTimeStr:lastCandleTimeObj.format("DD-MM-YYYY")
            })
            $store.dispatch('updateChartStartingTime', startingTime)
            $store.dispatch('updateChartEndingTime', endingTime)
            this.chart.tv.setRange(
              timeMap[0],
              timeMap[1]
            )
            this.$store.commit("SET_CHART_MANUAL_INTERACTION", false)
            localStorage.setItem("TimeData", JSON.stringify([startingTime, endingTime]))
          } else {


            let startingTimeObj = moment(range[0])
            let lastCandleTimeObj = moment(range[1])
            let endingTime = lastCandleTimeObj.valueOf()

            let timeMap = [
              this.selectedDataType ? ti_map_config.t2i(range[0], candleData) : range[0],
              this.selectedDataType ? ti_map_config.t2i(range[1], candleData) : range[1]
            ]
            let timeMap2 = [
              ti_map_config.gt2i(range[0], candleData),
              ti_map_config.gt2i(range[1], candleData)
            ]
            // range[0]
            console.log("setChartRange before setRange", timeMap)
            console.log({
              chartDataLength: candleData.length,
              startingTime: startingTimeObj.valueOf(),
              startingTimeId: timeMap[0],
              startingTimeId2: timeMap2[0],
              startingTimeStr: startingTimeObj.format("DD-MM-YYYY"),
              endingTime,
              endingTimeId: timeMap[1],
              endingTimeId2: timeMap2[1],
              endingTimeStr: lastCandleTimeObj.format("DD-MM-YYYY")
            })
            this.chart.tv.setRange(
              // range[0],range[1]
              timeMap[0], timeMap[1]
            )
            setTimeout(() => {
              if (refsForRange) {
                this.tv.$refs?.chart?.range_changed_by_time(range[0], range[1])
              }
            })
            localStorage.setItem("TimeData", JSON.stringify(timeMap))
          }

          if (callback) {
            callback(this.chart)
          }
        }
      })
    },
    addIndicator(type){
      // console.log("addIndicator",type)
      let proto = this.overlays.find((x) => x.name === type);
      //console.log("proto == ", JSON.stringify(proto))
      if (proto && proto.methods.meta_info) {
        let meta = proto.methods.meta_info();
        let side = meta.preset?.side || 'onchart'
        const query = `${side}.${type}`;
        let chartData = this.chart.get(query)
         console.log("chartData",chartData)
        for (const data of chartData) {
          console.log(data)
          this.chart.del(data.id)
        }

        //meta.preset.settings.lineWidth = 25;
        console.log("New meta.preset.settings", meta.preset.settings);
        console.log("New meta.preset.settings", this.chart);
        const oveylay_tv = {
          type,
          name: meta.preset.name,
          desc: meta.desc,
          settings: meta.preset.settings,
          tf: this.getTimeFrame,
          settingsLabelMap: meta.preset.settingsLabelMap ? meta.preset.settingsLabelMap : {},
          side,
          // data: manuallyAddedFromTools ? chartData : []
          data: []
        };
        let id = this.chart.add(side, oveylay_tv)
        setTimeout(() => {
          this.resetChart()
          // console.log("meta",meta,id)
        })
      }
    },
    resetChart() {
      // console.log("update_layout",this.chart?.tv?.$refs?.chart?.update_layout)
      this.chart.tv.resetChart(true)
    },
    toggleSideBarYAxis() {
      if (this.$refs.chartContainer?.toggleSideBarYAxis) {
        this.$refs.chartContainer.toggleSideBarYAxis()
      }
    },
    updateTimeFrame(timeFrame) {
      console.log("updateTimeFrame", timeFrame)
      this.tv.set('chart.tf', `1${timeFrame}`)
      this.resetChart()
    },
    remove_overlay(name) {
      const e = { type: name, side: 'auto' }
      let preset = this.get_preset(e.type) || {}
      //console.log('add_overlay preset  --- ',preset,updatedProps)

      if (preset.side) e.side = preset.side
      // let onchart = this.chart.data.onchart
      let sideData = this.dc.data[e.side]
      let chartData = sideData.filter((a) => a.type === name)
      // if(chartData && chartData.length){
      //   this.dc.del(chartData[0].id)
      // }
      for (const data of chartData) {
        this.dc.del(data.id)
      }
      setTimeout(() => {
        
        this.resetChart()
      })
    },
    get_preset(type) {
      console.log(this.tv.overlays, type)
      let proto = this.dc.tv.overlays.find((x) => x.name === type);
      // console.log("proto == ",JSON.stringify(proto))
      if (proto && proto.methods.meta_info) {
        let meta = proto.methods.meta_info();
        return meta.preset;
      }
    },
  },
  data() {
    
    return {
      overlays: Object.values(Overlays),
      names: Object.keys(Overlays),
      extensions: Extensions,
      xsett: {}
    }
  },
  created() {
    let hasDrawingStoreInLocal = localStorage.getItem('drawn_data')
      if (hasDrawingStoreInLocal) {
        hasDrawingStoreInLocal = JSON.parse(hasDrawingStoreInLocal)
        const previousChart=this.chart;
        previousChart.data.onchart=hasDrawingStoreInLocal;
      }
   
      console.log("overlay_Updated",this.chart)
  },
  beforeDestroy() {
    chartEvents.$off("setChartRange")
    chartEvents.$off("ADD_INDICATOR")
    chartEvents.$off("REMOVE_INDICATOR")
    chartEvents.$off("toggleSideBarYAxis")
    chartEvents.$off("updateTimeFrame")
    console.log("beforeDestroy", this.chart)
    window.chart = null
  },
  destroyed() {
    console.log("destroyed", this.chart)
  },
  mounted() {
    chartEvents.$on("setChartRange", this.setChartRange)
    chartEvents.$on("ADD_INDICATOR", this.addIndicator)
    chartEvents.$on("REMOVE_INDICATOR", this.remove_overlay)
    chartEvents.$on("toggleSideBarYAxis", this.toggleSideBarYAxis)
    chartEvents.$on("updateTimeFrame", this.updateTimeFrame)
    this.$store.commit('SET_DATA_CUBE', { dc: this.chart })
    this.$store.commit('SET_TRADING_CHART_REFERENCE', { tradingChartReference: this.$refs.chartContainer })
    this.$emit('ChartLoad',this.$refs.chartContainer,this.chart)
    window.chart = this.chart
    this.resetChart()
  },
  computed: {
    ...mapGetters(["getTimeFrame", "getChartType", "showChartVolume", "tv", "dc", "selectedDataType", "symbolName"])
  },
  watch: {

    // data:{
    //   async handler(val){
    //     // console.log("Candle Watch Updated",val.length)
    //     // this.chart.set('chart.tf', this.timeFrame)
    //     // const settings = {upper:this.chartUpperRange,lower:this.chartLowerRange};
    //     // console.log("val",val)
    //     this.chart.merge('chart.tf', `1${this.getTimeFrame}`)
    //     this.chart.set('chart.data', val)
    //     await this.$nextTick()
    //     // this.$emit("chartUpdate")
    //     // let ranges = this.chart.tv.getRange()
    //     // console.log("trainingModeIndex",ranges,this.chart.tv.ti_map)
    //   },
    //   deep:true
    // }
  }
}
</script>