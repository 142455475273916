<template>
  <div class="tvjs-x-window" :style="style" ref="win">
    <div class="tvjs-x-window-head">
      <div class="tvjs-x-window-title" @mousedown="onMouseDown">
        {{ title }}
      </div>
      <div class="tvjs-x-window-close" @click="$emit('close')">╳</div>
    </div>
    <div class="tvjs-x-window-body">
      <slot></slot>
    </div>
  </div>
</template>
<script>

import Dragg from "./drag";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Window",
  mixins: [Dragg],
  props: ["title", "tv"],
  mounted() {
    this.ww = this.$refs.win.clientWidth;
    this.wh = this.$refs.win.clientHeight;
    this.x = this.tvw * 0.5 - this.ww * 0.5;
    this.y = this.tvh * 0.5 - this.wh * 0.5;
  },
  computed: {
    style() {
      return {
        // top: `${this.y}px`,
        // left: `${this.x}px`,
        top: `20px`,
        left: `10px`,
      };
    },
    tvw() {
      return this.$props.tv.width;
    },
    tvh() {
      return this.$props.tv.height;
    },
  },
  data() {
    return {
      ww: 0,
      wh: 0,
      x: 0,
      y: 0,
    };
  },
};
</script>
<style scoped>
.tvjs-x-window {
  position: absolute;
  background-color: rgb(21, 21, 21);
  border-radius: 3px;
  border-width: 5px;
  pointer-events: all;
  /*padding-left: 7px;*/
  z-index: 100;
  color: #000;
  box-sizing: border-box;
  font-size: 1rem;
  border-color: blue;
}
.tvjs-x-window-body {
  padding: 1rem;
}
.tvjs-x-window-title {
  user-select: none;
  width: 100%;
  min-width: 270px;
  padding: 1rem;
  background-color: rgb(21, 21, 21);
  color: rgb(182, 182, 182);
  font-family: Trebuchet MS, roboto, ubuntu, sans-serif;
  font-size: 20px;
}
.tvjs-x-window-head {
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  background-color: rgba(239, 68, 68, 0.9);
  height: 40px;
}
.tvjs-x-window-close {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 0.25rem; */
  padding: 0 1rem;
}
.tvjs-x-window-close:hover {
  background-color: rgb(153, 27, 28);
}
</style>
