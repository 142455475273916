
// Extension's controller

import { Utils } from 'trading-vue-js'
import SettingsWin from './SettingsWin.vue'
// import Vue from 'vue'

export default class Main {

    constructor(tv, dc) {
        // console.log("Main Extension Data Indicators",tv, dc)
        this.widgets = {}
        this.tv = tv
        this.dc = dc
    }

    // Listens to all tvjs events, creates new widgets
    update(e) {
        switch(e.event) {
            case 'legend-button-click':
                console.log("button is clicked")
                // eslint-disable-next-line no-case-declarations
                let id = `SettingsWin-${Utils.uuid2()}`
                console.log("button is clicked",id)
                // eslint-disable-next-line no-case-declarations
                let args = e.args[0]
                if (args.button !== 'settings') break
                console.log("args: " , args)
                try {
                    let ov = this.dc.data[args.type][args.dataIndex]
                    let f = Object.values(this.widgets)
                        .find(x => x.data.ov === ov)
                    if(f) {
                        this.tv.$delete(this.widgets, f.id)
                        break
                    }
                    let config = {
                        id, cls: SettingsWin, data: { ov: ov },props:{tv:this.tv}
                    };
                    console.log('config',config)
                    this.tv.$set(this.widgets, id, config)
                } catch(e) {
                    console.log(e)
                }
                break
            case 'remove':
                console.log("remove tool", this.dc.data.selected)
                console.log('event',e)
                break
        }
    }

    remove_widget(id) {
        this.tv.$delete(this.widgets, id)
    }

}
