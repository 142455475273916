<script>
// Renedrer for candlesticks + volume (optional)
// It can be used as the main chart or an indicator

import { Overlay,layout_cnv } from "trading-vue-js";
import Candle from "./primitives/candle";
import Volbar from "./primitives/volbar.js";
import Price from "./primitives/price.js";
import priceline from './priceline.js'
import HighestPriceLine from "./primitives/highestPriceLine.js";
import LowestPriceLine from "./primitives/lowestPriceLine.js";
import {mapGetters} from "vuex";

export default {
  name: "Candles",
  mixins: [Overlay,priceline],
  data() {
    return {
      canvas:null, 
      // price: {}, 
      HighestCandle:{},
      LowestCandle:{} , updateData:1};
  },

  // Define internal setting & constants here
  computed: {
    ...mapGetters(["showChartVolume"]),
    sett() {
      return this.$props.settings;
    },
    show_volume() {
      return this.showChartVolume;
    },
   
    colorCandleUp() {
      return this.sett.colorCandleUp || this.$props.colors.candleUp;
    },
    colorCandleDw() {
      return this.sett.colorCandleDw || this.$props.colors.candleDw;
    },
    colorWickUp() {
      return this.sett.colorWickUp || this.$props.colors.wickUp;
    },
    colorWickDw() {
      return this.sett.colorWickDw || this.$props.colors.wickDw;
    },
    colorWickSm() {
      return this.sett.colorWickSm || this.$props.colors.wickSm;
    },
    colorVolUp() {
      return this.sett.colorVolUp || this.$props.colors.volUp;
    },
    colorVolDw() {
      return this.sett.colorVolDw || this.$props.colors.volDw;
    },
    isArrow() {
      return "isArrow" in this.sett ? this.sett.isArrow : false;
    },
    decimalPlace() {
      // return this.sett?.decimalPlace || 2;
      return "decimalPlace" in this.sett ? this.sett.decimalPlace : 2;
    },
  },
  methods: {
    meta_info() {
      return { author: "C451", version: "1.2.1" };
    },
    init() {
      this.priceInit()
      this.HighestCandle = new HighestPriceLine(this);
      this.LowestCandle = new LowestPriceLine(this);
      // this.HighestCandle = new HighestPriceLine(this);
    },
    draw(ctx) {
      // If data === main candlestick data
      // render as main chart:
      if (this.$props.sub === this.$props.data) {
        var cnv = {
          candles: this.$props.layout.candles,
          volume: this.$props.layout.volume,
        };
        // Else, as offchart / onchart indicator:
      } else {
        cnv = layout_cnv(this);
      }

      // if (this.showChartVolume) {
      //   var cv = cnv.volume;
      //   for (var i = 0, n = cv.length; i < n; i++) {
      //     new Volbar(this, ctx, cv[i]);
      //   }
      // }

      var cc = cnv.candles;
      let highest = cc[0];
      let lowest = cc[0];
      // console.log("cc.length",cc.length)
      for (var i = 0, n = cc.length; i < n; i++) {
        
        if (highest.raw[2] < cc[i].raw[2]) {
          highest = cc[i];
        }

        if(lowest.raw[3]>cc[i].raw[3]){
          lowest=cc[i]
        }
        new Candle(this, ctx, cc[i]);
      }
      // console.log("heighest:",highest)
      // const highLowCandlesData={
      //   highestCandle:highest,
      //   lowestCandle:lowest
      // }
      
      // this.$store.commit("SET_HIGH_LOW_CANDLE",highLowCandlesData)
      if (this.price_line) this.price.draw(ctx);
      this.HighestCandle.draw(ctx,highest)
      this.LowestCandle.draw(ctx,lowest)

    },
    use_for() {
      return ["Candles"];
    },

    // In case it's added as offchart overlay
    y_range() {
      var hi = -Infinity,
        lo = Infinity;
      for (var i = 0, n = this.sub.length; i < n; i++) {
        let x = this.sub[i];
        if (x[2] > hi) hi = x[2];
        if (x[3] < lo) lo = x[3];
      }
      return [hi, lo];
    },
  },
  watch: {
    showChartVolume(n,o){
        // let x = layout_cnv(this)
        // this.draw(x)
        // console.log("lines show",x)
    },
    isArrow: {
      handler: function (value) {
        // console.log("candles isArrows", value, this.price);
        this.price = new Price(this);
        this.HighestCandle = new HighestPriceLine(this);
        this.LowestCandle = new LowestPriceLine(this);
      },
    },
  },
  mounted() {
    // console.log("candles mounted", this.$props);
  },
};
</script>
