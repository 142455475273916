<script>
import { Overlay } from 'trading-vue-js'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'CompareChart',
  mixins: [Overlay],
  mounted() {
    this.onMount()
  },
    beforeUnmount() {
    this.tv.data.set('chart.data', this.getSymbolData)
  },
  methods: {
    meta_info() {
      return { author: 'LakshanPerera', version: '1.1.2' }
    },
    init() {
      this.onMount()
    },
    onMount() {
      // utils
      console.log('onMount getMultipleSymbolData',this.getMultipleSymbolData)
      const s2t = str => moment(str).valueOf()

      let startingDate = 0
      let maxSize = 0
      for (const sym of this.getMultipleSymbolData) {
        if (s2t(sym.data[0].date) > startingDate) {
          startingDate = s2t(sym.data[0].date)
        }
        maxSize = Math.max(maxSize, sym.data.length)
      }

      // set the starting point to the max of current chart starting point(3M, Y etc.)
      startingDate = Math.max(startingDate, this.getChartStartingTime)

      // for storing actual data for each symbol
      this.symbol0 = [] // current symbol
      this.symbol1 = []
      this.symbol2 = []
      this.symbol3 = []
      this.symbol4 = []

      this.initialPrices = [-1, -1, -1, -1, -1]
      // start adding data
      for (let i = 0; i < maxSize; i++) {
        for (let c = 0; c < this.getMultipleSymbolData.length; c++) {
          const symbol = this.getMultipleSymbolData[c]
          // console.log(symbol.symbol ,JSON.stringify(symbol.data[i]))
          if (i >= symbol.data.length) continue
          if (s2t(symbol.data[i].date) >= startingDate) {
            // set the initial price for the percentage correction
            if (this.initialPrices[c] < 0) {
              this.initialPrices[c] = symbol.data[i].close
            }

            const pricePct =
              ((symbol.data[i].close - this.initialPrices[c]) * 100.0) /
              this.initialPrices[c]
            const d = [s2t(symbol.data[i].date), pricePct]

            // add volume to the current chart symbol data
            if (c === 0) {
              d.push(symbol.data[i].volume)
              d.push(symbol.data[i].value)
            }

            this[`symbol${c}`].push(d)
          }
        }
        console.log(this[`symbol1`].length)
        console.log(this[`symbol2`].length)
        console.log(this[`symbol3`].length)
        console.log(this[`symbol4`].length)
      }

      // percentage correction
      const maxIndex = Math.max(
        this.symbol0.length,
        this.symbol1.length,
        this.symbol2.length,
        this.symbol3.length,
        this.symbol4.length
      )

      // set the alt data
      this.altData = []
      for (let i = 0; i < maxIndex; i++) {
        const date =
          this.symbol0[i]?.[0] ||
          this.symbol1[i]?.[0] ||
          this.symbol2[i]?.[0] ||
          this.symbol3[i]?.[0] ||
          this.symbol4[i]?.[0]

        const close0 = this.symbol0[i]?.[1] ?? 0
        const close1 = this.symbol1[i]?.[1] ?? 0
        const close2 = this.symbol2[i]?.[1] ?? 0
        const close3 = this.symbol3[i]?.[1] ?? 0
        const close4 = this.symbol4[i]?.[1] ?? 0

        const max = Math.max(close0, close1, close2, close3, close4)
        const min = Math.min(close0, close1, close2, close3, close4)
        this.altData.push([
          date,
          max,
          max,
          min,
          min,
          this.symbol0[i]?.[2] ?? 0,
          null,
          this.symbol0[i]?.[3] ?? 0
        ])
      }

      // for the left v-line
      this.startingDate = this.altData[0][0]

      this.tv.data.set('chart.data', this.altData)
    },
    draw(ctx) {
      if (this.symbol1.length) {
        this.drawLineChart(ctx, 'red', this.symbol1)
      }
      if (this.symbol2.length) {
        this.drawLineChart(ctx, 'blue', this.symbol2)
      }
      if (this.symbol3.length) {
        this.drawLineChart(ctx, 'green', this.symbol3)
      }
      if (this.symbol4.length) {
        this.drawLineChart(ctx, 'yellow', this.symbol4)
      }
      if (this.symbol0.length) {
        this.drawLineChart(ctx, 'orange', this.symbol0)
      }

      // draw the vertical line
      const layout = this.$props.layout
      ctx.lineWidth = this.verticalLineWidth
      ctx.strokeStyle = this.verticalLineColor
      ctx.beginPath()
      const x = layout.t2screen(this.startingDate) // x - Mapping
      ctx.setLineDash([10, 10])
      ctx.moveTo(x, 0)
      ctx.lineTo(x, this.layout.height)
      ctx.stroke()
    },
    drawLineChart(ctx, color, data) {
      ctx.lineWidth = this.lineWidth
      ctx.strokeStyle = color
      ctx.beginPath()
      const { t2screen, $2screen } = this.$props.layout
      for (let k = 0, n = data.length; k < n; k++) {
        let p = data[k]
        let x = t2screen(p[0])
        let y = $2screen(p[1])
        ctx.lineTo(x, y)
      }
      ctx.stroke()
    },
    use_for() {
      return ['CompareChart']
    },
    data_colors() {
      return [this.color]
    }
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters([
      'getMultipleSymbolData',
      'tv',
      'getSymbolData',
      'getChartStartingTime'
    ]),
    sett() {
      return this.$props.settings
    },
    lineWidth() {
      return this.sett.lineWidth || 1
    },
    color() {
      const n = this.$props.num % 5
      return this.sett.color || this.COLORS[n]
    },
    data_index() {
      return this.sett.dataIndex || 1
    },
    // Don't connect separate parts if true
    skip_nan() {
      return this.sett.skipNaN
    },
    show_volume() {
      return false
    },
    verticalLineWidth() {
      return this.sett.verticalLineWidth || 2.0
    },
    verticalLineColor() {
      return this.sett.verticalLineColor || '#ccc'
    }
  },
  data() {
    return {
      COLORS: ['#42b28a', '#5691ce', '#612ff9', '#d50b90', '#ff2316']
    }
  },
  watch: {
    getMultipleSymbolData(newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        this.onMount()
      } else {
        for (let i = 0; i < newValue.length; i++) {
          if (
            newValue[i].symbol !== oldValue[i].symbol ||
            newValue[i].timeFrame !== oldValue[i].timeFrame
          ) {
            this.onMount()
            return
          }
        }
      }
    },
    getChartStartingTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.onMount()
      }
    }
  }
}
</script>
