<template>
  <tailwind-modal  :show-modal="showModal" :showHeader="false" class="z-[105]">
      <div class="flex justify-between bg-[#1E2125] p-1 border-b-2 border-[#2E312F] text-sm text-[#C6C9CD] items-center">
        
      <div class=""></div>
      <div class="">Indicators</div>
      <div class="">

        <svg @click="HideChartModal" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>

      </div>
   
      </div>
    <div class="min-w-[38rem] px-10 py-4 chartModalDIv">
      
      <div class="flex pb-4">Add Indicators</div>

      <!-- <div class="h-[3.5vh] mb-4">
        <input
          type="text"
          id="findStock"
          placeholder="Search in stock..."
          class="p-2 border-box bg-[#1E2224] text-[#E5E9EC] block w-full h-full text-[.9vw] text-gray-200 border border-[#7582783d] shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500"
        />
      </div> -->

      <!-- <div class="bg-[#1E2224] mb-4 border-box border border-[#7582783d] shadow-inner rounded-sm" >
        <div class="relative">
          <div
            class="flex gap-[10px] absolute inset-y-0 right-[10px] items-center cursor-pointer"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.45">
                <path
                  d="M6.56739 3.80769C6.56739 5.52491 5.23719 6.86538 3.6587 6.86538C2.0802 6.86538 0.75 5.52491 0.75 3.80769C0.75 2.09048 2.0802 0.75 3.6587 0.75C5.23719 0.75 6.56739 2.09048 6.56739 3.80769Z"
                  stroke="#E5E9EC"
                  stroke-width="1.5"
                />
                <path
                  d="M6.31943 6.57662L8.31508 8.65355"
                  stroke="#E5E9EC"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </g>
            </svg>
          </div>
            id="default-search"
            style="border-radius: 5px"
          <input
            type="text"
            class="w-full p-1 bg-[#1E2224] border border-[#151515] placeholder:text-[#888c8f] text-white focus:outline-none"
            placeholder="Search in stock..."
            value=""
            required
          />
            v-model="search"
        </div>
      </div> -->

      <div class="section-div">
        <div class="section-child1">
          <ul>
            <li
              v-for="(trend, i) of trends"
              :key="i"
              @click="handleSelectedTrend(i)"
              :class="[selectedList == i ? 'bg-[#212121] ' : '']"
            >
              <p
                :class="[
                  selectedList == i
                    ? 'text-[#04B32C] text-[.9vw] '
                    : 'text-[.9vw]',
                ]"
              >
                {{ trend.name }}
              </p>
            </li>
          </ul>
        </div>

        <div class="section-child2">
          <ul>
            <li
              v-for="(trendListName, i) of showTrendList"
              :key="i"
              :class="[
                selectedListIndex === i
                  ? 'hover:bg-[#212121]'
                  : 'hover:bg-[#212121]',
              ]"
            >
              <!-- @click="SelectedListItem(i, trendListName)" -->

              <div class="flex items-center justify-between">
                <span
                  :class="[
                    selectedListIndex === i ? ' text-[.8vw]' : 'text-[.8vw]',
                  ]"
                >
                  {{ trendListName.desc }}
                </span>
                <span>
                  <span v-if="getSelectedIndicators.includes(trendListName.name)">
                    <svg
                      @click="deSelectedListItem(trendListName)"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="red"
                      class="w-6 h-6 cursor-pointer"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </span>
                  <span v-else>
                    <svg
                      @click="SelectedListItem(i, trendListName)"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="gray"
                      class="w-6 h-6 cursor-pointer"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </span>
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- section end -->

      <div class="buttons flex justify-end">
        <button
          type="submit"
          @click="HideChartModal()"
          class="px-4 font-semibold py-1 text-sm ok-button mt-4"
        >
          <!-- class="bg-red-500 mr-2 hover:bg-red-800 px-8 mt-6 font-semibold py-2 text-sm" -->
          CLOSE
        </button>
      </div>
    </div>
  </tailwind-modal>
</template>

<script>
import TailwindModal from "@/components/popups/TailwindModal";
import {mapGetters} from "vuex";
import ChartEventNew from "@/components/vue_chart/chartEvent";
import {trendConfig} from "@/components/vue_chart/TrendConfig";
let chartEvents;
chartEvents = new ChartEventNew();

export default {
  name: "ChartModal",
  components: {
    TailwindModal,
  },
  props: ["showModal"],
  data() {
    
    return {
      watchListName: "",
      selectedTrendSubList: "",
      seletecTrend: "",
      selectedIndicators:[],
      trends: trendConfig,
      showTrendList: [],
      selectedList: 0,
      selectedListIndex: 0,
    };
  },
  methods: {
    HideChartModal() {
      // this.seletecTrend = "";
      // this.selectedTrendSubList = "";
      // (this.selectedList = 0),
      //   (this.selectedListIndex = 0),
      this.$emit("HideModal");
    },
    
    handleSelectedTrend(index) {
      this.selectedList = index;
      this.showTrendList = this.trends[index].list;

      //entire selected object
      this.seletedTrend = this.trends[index];
    },
    deSelectedListItem(value){
    this.selectedIndicators= this.selectedIndicators.filter((indicator)=> indicator !=value.name)
    this.$store.dispatch("removeIndicator",value.name)
    this.remove_overlay(value.name)
    },
    
    remove_overlay(name) {
      const e = { type: name, side: 'auto' }
      let preset = this.get_preset(e.type) || {}
      // console.log('add_overlay preset  --- ',preset,updatedProps)

      if (preset.side) e.side = preset.side
      // let onchart = this.chart.data.onchart
      let sideData = this.dc.data[e.side]
      let chartData = sideData.filter((a) => a.type === name)
      // if(chartData && chartData.length){
      //   this.dc.del(chartData[0].id)
      // }
      for (const data of chartData) {
        this.dc.del(data.id)
        // this.dc.tv.resetChart(true);
      }
    },

    SelectedListItem(index, value) {
      // console.log("value",value)
      this.selectedListIndex = index;
      this.selectedTrendSubList = value;
      this.selectedIndicators.push(value.name)
      this.$store.dispatch("addIndicator",value.name)
      // console.log(this.selectedIndicators)
      chartEvents.$emit('ADD_INDICATOR',value.name)
      // this.add_overlay(
      //   value.name,
      //   value.gridid,
      //   value.props,
      //   value.datatype,
      //   value.multi_value
      // );
      // this.$emit("HideModal");
      // this.$emit("HideModal", false);
    },
    get_preset(type) {
      console.log(this.tv.overlays,type)
      let proto = this.dc.tv.overlays.find((x) => x.name === type);
      // console.log("proto == ",JSON.stringify(proto))
      if (proto && proto.methods.meta_info) {
        let meta = proto.methods.meta_info();
        return meta.preset;
      }
    },
    avg_grid_h(ovs) {
      if (!ovs.length) return 0.25;
      let gh = 0;
      for (var ov of ovs) {
        if (ov.grid && typeof ov.grid.height === "number") {
          gh += ov.grid.height;
        }
      }
      return gh / ovs.length;
    },
    add_overlay(
      name,
      gridId,
      updatedProps = undefined,
      dataType = undefined,
      multi_value = false,
      itemId = 0,
      updateRefs = false
    ) {
      // console.log({name, gridId,updatedProps ,dataType,multi_value,itemId ,updateRefs})
      const e = { type: name, side: "auto" };
      let preset = this.get_preset(e.type) || {};
      // console.log('preset value',preset)
      if (preset.side) e.side = preset.side;
      let offchart = this.dc.data.offchart;
      let onchart = this.dc.data.onchart;

      if (updatedProps) {
        if (dataType === "object") {
          for (const key in updatedProps) {
            if (Object.hasOwnProperty.call(updatedProps, key)) {
              const element = updatedProps[key];
              // if(preset.settings[key]){
              preset.settings[key] = element;
              // }
            }
          }
        } else if (dataType === "string") {
          preset.settings["length"] = updatedProps;
        }
      }

      // console.log('offchart',offchart)
      // console.log('onchart',onchart)
      // console.log('e.side',e.side)

      if (e.side === "onchart") {
        // console.log('multi_value',multi_value)
        if (multi_value) {
          let list = this.dc.get(name);
          let chartObj = list.find((i) => i.id === `onchart.${name}${itemId}`);
          // console.log('list',list,'chartObj',chartObj)

          // let data = []
          let newRef = false;
          if (updateRefs) {
            newRef = true;
            // data = updateRefs[itemId]
          }

          if (chartObj) {
            let newSettings = {
              settings: { ...chartObj.settings, ...preset.settings },
            };
            this.dc.merge(chartObj.id, newSettings);
          } else {
            let multiValChart = {
              name: preset.name,
              id: itemId,
              type: e.type,
              data: [],
              settings: preset.settings || {},
            };

            this.dc.add("onchart", multiValChart);

            if (newRef) {
              // this.dc.tv.resetChart(true);
            }
          }
        } else {
          let chartData = onchart.find((indi) => indi.type === e.type);
          if (chartData) {
            // console.log()
            let newSettings = {
              settings: { ...chartData.settings, ...preset.settings },
            };
            // console.log("chart updates",newSettings)
            this.dc.merge(chartData.id, newSettings);
            // console.log(this.dc.get_one(chartData.id))
            // this.dc.tv.resetChart(true)
            return;
          }
          this.dc.add("onchart", {
            name: preset.name,
            type: e.type,
            data: [],
            settings: preset.settings || {},
          });
        }
      } else {
        let chartData = offchart.find((indi) => indi.type === e.type);
        if (chartData) {
          // console.log(chartData.id)
          this.dc.merge(chartData.id, preset);
          // this.dc.tv.resetChart(true);
          return;
        }
        const h = this.avg_grid_h(offchart);

        // construct the new indicator
        const newIndicator = {
          name: preset.name,
          type: e.type,
          data: [],
          settings: preset.settings || {},
          grid: { height: h, id: gridId },
        };

        // add the new indi directly if it is a custom grid indicator
        if (gridId) {
          return this.dc.add("offchart", newIndicator);
        }

        // old indicator list
        const oldList = this.dc.get("offchart").map((ov) => ({
          name: ov.name,
          type: ov.type,
          data: [],
          settings: ov.settings,
          grid: ov.grid,
        }));

        // delete all offchart overlays
        this.dc.del("offchart");

        const customGridIndicators = [];
        // let addRes = null
        for (const indi of oldList) {
          // console.log(indi)
          if (indi.grid.id) {
            customGridIndicators.push(indi);
          } else {
            this.dc.add("offchart", indi);
          }
        }

        // add the new indi
        this.dc.add("offchart", newIndicator);

        // add the old custom grid indis
        customGridIndicators.forEach((indi) => {
          // console.log(indi)
          this.dc.add("offchart", indi);
        });
      }
    },
  },
  mounted() {
    this.selectedList = 0;
    this.showTrendList = this.trends[0].list;
    this.selectedListItem = 0;
    this.seletedTrend = this.trends[0];
  },
  computed: {
    ...mapGetters(["dc", "tv","getSelectedIndicators"]),
    tradingChart: function () {
      return this.$store.state.tradingChartReference;
    },
  },
};
</script>

<style scoped>
.chartModalDIv {
  /* border: 0.3px solid rgba(2, 189, 45, 0.3); */
  box-sizing: border-box;
  scrollbar-color: red;
  border-radius: 3px;
  background-color: #151515;
  /* new work */
  display: flex;
  flex-direction: column;
}

.ok-button {
  border: 0.6px solid #FF0A00;
  border-radius: 3px;
  color: #FF0A00;
  text-transform: uppercase;
}
.ok-button:hover {
  background: linear-gradient(
    270deg,
    rgba(255, 57, 57, 0.24) 0%,
    rgba(255, 70, 64, 0) 50%,
    rgba(248, 55, 42, 0.24) 100%
  );
}

.section-div {
  display: flex;
  height: 30vh;
  width: 100%;
  background-color: #151515;
}

.section-child1 {
  height: 100%;
  width: 30%;
  border: 0.3px solid #7582783d;
}
.section-child1 ul {
  color: whitesmoke;
  padding: 1vh 0.5vw;
}
.section-child1 ul li {
  display: flex;
  justify-content: flex-start;
  gap: 0.5vw;
  padding: 0.7vh 0.5vw;
  cursor: pointer;
}
.section-child2 {
  height: 100%;
  width: 70%;
  border: 0.3px solid #7582783d;
}
.section-child2 ul {
  color: whitesmoke;
  padding: 1vh 0.5vw;
  /* cursor: pointer; */
}
.section-child2 ul li {
  padding: 0.5vh 0.3vw;
}

/* 
    .bottomDivModal{
        height: 20%;
        width: 100%;
        background-color: white;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 5px;
    }
    button{
        background-color: black;
        color: white;
        padding: 0;
        padding: 10px 15px;
    } */
</style>
