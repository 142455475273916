<script>
import { Overlay } from 'trading-vue-js'
import { getIndicatorSettings } from '@/extensions/indicatorSettings/defaultIndicatorSettings';
export default {
    // eslint-disable-next-line vue/multi-word-component-names
  name: 'Value',
  mixins: [Overlay],
  methods: {
    meta_info() {
      const indicatorName = 'Value';
      const {  lineWidth, lineType, color } = getIndicatorSettings(indicatorName);
      return {
        author: 'LakshanPerera',
        version: '1.0.0',
        desc: 'Value Indicator',
        preset: {
          name: 'Value',
          side: 'offchart',
          settings: {
            lineWidth,
            color,
            lineType
          }
        }
      }
    },
    use_for() {
      return ['Value']
    },
    calc() {
      return {
        conf: { renderer: 'Spline' },
        update: `
                return ohlcv[iter][7]
              `
      }
    }
  }
}
</script>
