<template>
  <div
    class="wrapper"
    style="width: 100%; height: 100%;"
      v-if="isSettingsPicker"
      v-click-outside="onClickOutside"
  >
    <div class="w-full flex jusitfy-between h-full">
    <div class="w-full cursor-pointer">
      <button @click.prevent="open = !open" :style="style" />
    </div>
<!--    <div class="w-[50px]">
      <span class="cursor-pointer text-gray-200" @click.prevent="open = !open" >{{open ? 'Close' : 'Open'}}</span>
    </div>-->
    </div>
    <div style="position: absolute; z-index: 100001; left:250px; bottom:-50px;" class="mt-4" v-on:keyup.enter="CloseSketch()" v-if="open">
    <sketch-picker
        :value="value"
        @input="onUpdateColor"
        :preset-colors="presetColors"
       
        ></sketch-picker>
    </div>
  </div>
</template>

<script>
import { Sketch } from 'vue-color'
import vClickOutside from 'v-click-outside'
export default {
  name: 'ColorPicker',
  components: {
    'sketch-picker': Sketch
  },
  directives: {
      clickOutside: vClickOutside.directive
    },
  props: {
    isSettingsPicker: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: "#fff"
    },
    fieldRef: {
      type: String
    },
    type: {
      type: String
    },
    invisibleBackground: {
      type: Boolean
    }
  },
  data() {
    return {
      color: {},
      borderColor: {},
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      open: false
    }
  },
  methods: {
    onClickOutside (event) {
        console.log('Clicked outside. Event: ', event)
        this.open = false
      },
    onUpdateColor(color) {
      let backcolor = color.hex8

      // color with standard 30% transparency
      let newColor = backcolor.slice(0,-2) + "30";

      this.$emit('input', backcolor,this.fieldRef,this.type)
      // this.$emit('input', newColor,this.fieldRef,this.type)
    },
    onUpdateborderColor(borderColor) {
      // console.log(color,color.hex)
      // const { r, g, b, a } = borderColor.rgba
      this.$emit('input', borderColor.hex,this.fieldRef,this.type)
    }
  },
  computed: {
    /** @returns {String} */
    style() {
      if (this.invisibleBackground) return 'width: 100%; height: 100%'
      return `background: ${this.value}`
    },
    /** @returns {[String]} */
    presetColors() {
      return [
        // '#2780e3',
        // '#6ba8ec',
        // '#165ba8',
        // '#ff7518',
        // '#ffa365',
        // '#cb5200',
        // '#9954bb',
        // '#ba8bd1',
        // '#6f378b',
        // '#dee327',
        // '#e8ec6b',
        // '#a4a816',
        // '#ff0039',
        // '#ff4d74',
        // '#b30028',
        // '#3fb618',
        // '#61e436',
        // '#28720f',
        // '#000001',
        // '#242425',
        // '#48484a',
        // '#6c6c6f',
        // '#909094',
        // '#b4b4b9',
        // '#d8d8de',
        // '#ffffff'
      ]
    }
  },
  created() {
    // console.log('color value',this.value,this.fieldRef)
  }
}
</script>

<style scoped>
.btn-color {
  width: 32px;
  height: 32px;
  border-radius: 0.5rem;
  cursor: pointer;
}
.wrapper {
  position: relative;
}
.vc-sketch{
  background-color: #ffffff !important;
}
</style>  