<script>

import { Overlay } from 'trading-vue-js'
import { getIndicatorSettings } from '@/extensions/indicatorSettings/defaultIndicatorSettings';
export default {
  name: 'BB',
  mixins: [Overlay],
  methods: {
    meta_info() {
      const { lineWidth, color,backColor } = getIndicatorSettings('BB');
      return {
        author: 'StdSquad', version: '1.0.0',
        desc: 'Bollinger Bands',
        preset: {
          name: 'Bollinger Bands',
          side: 'onchart',
          settings: {
            lineWidth:parseFloat(lineWidth),
            color,
            showMid: true,
            backColor,
            
          }
        }
      }
    },
    use_for() { return ['BB'] },
    calc() {
      const { period,stddev } = getIndicatorSettings('BB');
      return {
        props: {
          days: { def:  parseInt(period), text: 'days' },
          stddev: { def: parseInt(stddev), text: 'StdDev' }
        },
        conf: { renderer: 'Channel' },
        update: `
                    let [m, h, l] = bb(close, days, stddev)
                    return [h[0], m[0], l[0]]
                `
      }
    }
  }
}
</script>