<template>
 

  <div class="flex w-full" style="background: #151515 ; position: relative;" v-if="render">

    <div class="w-2/12" id="leftContainer" style="border-right:1px solid #1e5c2f;position: fixed; z-index: 9999;">
      <SideBar  />
<!--      <top-bar />-->
    </div>
    <div class="flex flex-col w-10/12" id="rightContainer" style="position: absolute; left: 16.66667%;">
<!--      router view -->
      <top-bar/>

      <router-view ></router-view>
    </div>
  

  </div>


</template>
<script>
import SideBar from "@/components/sidebar/SideBar";
import TopBar from "@/components/sidebar/TopBar";
import {mapActions} from "vuex";
// import TopBar from "@/components/sidebar/TopBar";

export default {
  name: "DashboardPage",
  data(){
    return{

      render:false
    }

  },
  components: {
    TopBar,
    // TopBar,
    SideBar,
  },
   methods:{
    ...mapActions(["getUserData"])
  },
  async mounted() {
  let res =   await  this.getUserData();
    // this.loading = false
    if(res){
      this.render=true
    }
  
  },
}
</script>