<template>
  <div ref="chartTopRef" class="w-full h-[5vh] bg-[#151515]">
    <div class="w-full h-full flex items-center gap-1.5">
      <h1 class="font-bold text-white text-[.7rem]">
        {{this.symbolName}}
      </h1>
    
    
    
      <button
        class="w-[2.5%] h-full text-[.8vw] self-center"
        :class="t === selectedTimeFrame ? 'text-[#22e636]' : 'text-[#A5A9B2]'"
        v-for="(t, i) of timeFrames"
        :key="i"
        @click="selectTimeFrame(t)"
      >
        {{ t }}
      </button>
      <!--<button class="w-[2.5%] h-full text-[#2054DB] text-[.8vw] self-center">D</button>
            <button class="w-[2.5%] h-full text-[#A5A9B2] text-[.8vw] self-center">W</button>
            <button class="w-[2.5%] h-full text-[#A5A9B2] text-[.8vw] self-center">M</button>-->
      <span
        class="w-[.1%] h-full text-[#898d96] text-[.8vw] flex justify-center items-center"
        >|</span
      >
      <!-- <button class="w-[2.5%] h-full text-[#A5A9B2] text-[.8vw] self-center cursor-auto"
      
      >
     
        {{ selectedRange ? selectedRange : defaultSelectedRange }}
      </button> -->

<!--      <div class="dropdown relative w-[4%] px-2 h-full">
        <button
            class="dropdown-toggle text-[#A5A9B2] transition text-[.8vw] duration-150 ease-in-out space-x-4 flex items-center justify-between whitespace-nowrap w-[100%] h-[100%]"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
          {{ selectedDataTypeDesc }}

          <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-[30%] h-[30%] self-center"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
          >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
        <ul
            class="dd-menu dropdown-menu min-w-max absolute hidden z-[105] bg-black float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none"
            aria-labelledby="dropdownMenuButton1"
        >
          <li
              class="dropdown-item text-sm py-3 px-6 cursor-pointer font-normal block w-full whitespace-nowrap bg-transparent text-gray-200 hover:bg-gray-700"
              v-for="(d, i) of dataType"
              :key="i"
              @click="updateDataType(d)"
          >
            {{ d }}
          </li>
        </ul>
      </div>
      <span
          class="w-[.1%] h-full text-[#898d96] text-[.8vw] flex justify-center items-center"
      >|</span>
      -->
      <div class="dropdown relative w-[4%] px-2 h-full">
        <button
          class="dropdown-toggle text-[#A5A9B2] transition text-[.8vw] duration-150 ease-in-out space-x-4 flex items-center justify-between whitespace-nowrap w-[100%] h-[100%]"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
        {{ chartVisibleRange }}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-[30%] h-[30%] self-center"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>

        <ul
          class="dd-menu dropdown-menu min-w-max absolute hidden z-[105] bg-black float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none"
          aria-labelledby="dropdownMenuButton1"
        >
          <!-- class="dropdown-item text-sm py-3 px-6 font-normal cursor-pointer block w-full whitespace-nowrap bg-transparent text-gray-200 hover:bg-gray-700" -->
          <li
            class="dropdown-item text-sm py-3 px-6 cursor-pointer font-normal block w-full whitespace-nowrap bg-transparent text-gray-200 hover:bg-gray-700"
            v-for="(Ranges, i) of rangeMap"
            :key="i"
            @click="RangeSelected(i)"
            :class="timeRange == Ranges?.range ? 'pointer-events-none opacity-60':''"
          >
            {{ Ranges.range }}
          </li>
          <!--                    <li class="dropdown-item text-sm py-3 px-6 cursor-pointer font-normal block w-full whitespace-nowrap bg-transparent text-gray-200 hover:bg-gray-700">-->
          <!--                        5Y-->
          <!--                    </li>-->
        </ul>
      </div>

      <span
        class="w-[.1%] h-full text-[#898d96] text-[.8vw] flex justify-center items-center"
        >|</span
      >

      <button
        class="w-[4%] px-2 h-full text-[#A5A9B2] text-[.8vw] self-center flex  items-center justify-between dropdown-toggle"
        type="button"
        @click="handleToggleDropDown"
        id="dropdownMenuButton2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <svg
          id="candlestick"
          xmlns="http://www.w3.org/2000/svg"
          class="w-[100%] h-[35%] self-center text-[#A5A9B2]"
          viewBox="0 0 20 20.015"
        >
          <rect
            id="Rectangle_1"
            data-name="Rectangle 1"
            width="6.029"
            height="11.015"
            transform="translate(1 5.029)"
            fill="rgba(255,255,255,0.01)"
            stroke="#A5A9B2"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <line
            id="Line_7"
            data-name="Line 7"
            y2="4.261"
            transform="translate(3.985 1)"
            stroke="#A5A9B2"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <line
            id="Line_8"
            data-name="Line 8"
            y2="3.015"
            transform="translate(3.985 17)"
            stroke="#A5A9B2"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <line
            id="Line_9"
            data-name="Line 9"
            y1="3"
            transform="translate(15.985)"
            stroke="#A5A9B2"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <rect
            id="Rectangle_2"
            data-name="Rectangle 2"
            width="5.971"
            height="9.044"
            transform="translate(13.029 3)"
            fill="rgba(255,255,255,0.01)"
            stroke="#A5A9B2"
            stroke-linejoin="round"
            stroke-width="1"
          />
          <line
            id="Line_10"
            data-name="Line 10"
            y1="6"
            transform="translate(16.015 13.029)"
            stroke="#A5A9B2"
            stroke-linejoin="round"
            stroke-width="1"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-[40%] h-[40%] self-center"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      <ul
        class="dd-menu dropdown-menu min-w-max absolute hidden bg-black float-left list-none text-left rounded-lg shadow-lg hidden m-0 bg-clip-padding border-none"
        aria-labelledby="dropdownMenuButton2"
      >
        <li
          class="dropdown-item text-sm p-2 cursor-pointer font-normal block w-full whitespace-nowrap bg-transparent text-gray-200 hover:bg-gray-700"
          v-for="(item, index) in chartName"
          :key="index"
          @click="handleSelectedChart(item.id)"
          :class="selectedChartName == item?.id ? 'pointer-events-none opacity-60':''"
        >
          {{ item.name }}
        </li>
      </ul>

      <span
        class="w-[.1%] h-full text-[#898d96] text-[.8vw] flex justify-center items-center"
        >|</span
      >

      <button
        class="w-[7%] h-full text-[#A5A9B2] text-[.8vw] self-center flex justify-center"
      >
        <span
          class="h-full text-[#898d96] text-[.8vw] flex justify-center items-center"
          @click="showChart_Modal()"
          >Indicators</span
        >
      </button>

      <!-- <span
        class="w-[.1%] h-full text-[#898d96] text-[.8vw] flex justify-center items-center"
        >|</span
      >

      <button
        @click="handleAutoCheck"
        class="p-2 cursor-pointer text-[#898d96] space-x-1 text-sm items-center mx-3 flex"
      >
        <span class="text-[.8vw] flex justify-center items-center" :class="auto_y_axis ? 'text-[#22e636]' : 'text-[#898d96]'">auto</span>
      </button> -->

      <span
        class="w-[.1%] h-full text-[#898d96] text-[.8vw] flex justify-center items-center ml-1"
        >|</span
      >

      <button
        @click="handleCrosshair"
        class="p-2 cursor-pointer text-[#898d96] space-x-1 text-sm items-center mx-3 flex"
      >
        <svg
          class="cursor-pointer"
          width="29"
          height="15"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="15"
            y1="4.37114e-08"
            x2="15"
            y2="29"
            :stroke="crosshair ? '#22e636' : '#898D96'"
            stroke-width="2"
            stroke-dasharray="2 2"
          />
          <line
            y1="14"
            x2="29"
            y2="14"
            :stroke="crosshair ? '#22e636' : '#898D96'"
            stroke-width="2"
            stroke-dasharray="2 2"
          />
        </svg>
      </button>

      <span
        class="w-[.1%] h-full text-[#898d96] text-[.8vw] flex justify-center items-center"
        >|</span
      >

      <button
        @click="handlePriceLineCheck"
        class="p-2 cursor-pointer text-[#898d96] space-x-1 text-sm items-center mx-3 flex"
      >
        <svg
          class="cursor-pointer"
          width="29"
          height="2"
          viewBox="0 0 29 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            y1="1"
            x2="29"
            y2="1"
            :stroke="priceLine ? '#22e636' : '#898D96'"
            stroke-width="2"
            stroke-dasharray="2 2"
          />
        </svg>
      </button>

      <span
        class="w-[.1%] h-full text-[#898d96] text-[.8vw] flex justify-center items-center"
        >|</span
      >

      <button
        @click="handleVolumeCheck"
        class="p-2 cursor-pointer text-[#898d96] space-x-1 text-sm items-center mx-3 flex"
      >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.2" :stroke="showChartVolume ? '#22e636' : '#898D96'" class="w-5 h-5">
      <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
      </svg>
      </button>

      <span
        class="w-[.1%] h-full text-[#898d96] text-[.8vw] flex justify-center items-center"
        >|</span
      >

      <button
        @click="handlePriceHighlightCheck"
        class="p-2 cursor-pointer text-[#898d96] space-x-1 text-sm items-center mx-3 flex"
      >
        <svg
          width="21"
          class="cursor-pointer"
          height="15"
          viewBox="0 0 21 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 7.5L7.5 0.5H21V14.5H7.5L0.5 7.5Z"
            :fill="priceHighlight ? '#22e636' : '#898D96'"
          />
        </svg>
      </button>

      <span
        class="w-[.1%] h-full text-[#898d96] text-[.8vw] flex justify-center items-center"
        >|</span
      >
      <!-- @focusout="handleFocusOut" -->
      <div>
        <button
          @click="showCalendar()"
          class="flex text-[#898d96] justify-center items-center h-full w-full cursor-pointer"
        >
          Go To Date
        </button>
<!--        <div class="flex text-[#920410] justify-center items-center h-full w-full cursor-pointer">{{defineRangeInDate}}</div>-->
        <div
          v-if="showDatePicker"
        
          class="absolute mt-[1.5vh] z-[105] flex flex-col"
        >
          <button
            @click="handleFocusOut()"
            class="text-gray-200 flex justify-end text-right -mt-6"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>

          <DatePicker v-model="chartDate" color="green" is-dark> </DatePicker>
        </div>
      </div>
      <div  class="flex text-[#898d96] justify-center items-center h-full" style="margin-left:10%">
          <button class="px-1">
                <span >   
                  <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="12" fill="#898d96" />
                    <text  x="12" y="14" text-anchor="middle" font-size="16" fill="#000" font-family="Arial" dy=".3em">?</text>
                  </svg>
                </span>
              </button>

              <span class="px-1">Mode:</span>

              <button  v-if="isAlerts" title="New menu item" @click="sendData" @click.stop
              class=" px-3 default color-button dropdown-toggle text-[14px] hover:opacity-80 bg-[#000000] text-white relative cursor-pointer  actionBtn transition duration-150 ease-in-out flex items-center whitespace-nowrap"
              type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
              Default
              <!-- <router-link to="/chart" @click="toggleModal()">Click me</router-link> -->
            </button>
            <button v-if="!isAlerts" title="New menu item" @click="sendData" @click.stop
              class=" px-3 color-button dropdown-toggle text-[14px] hover:opacity-80 bg-[#02BD2D] text-black relative cursor-pointer  actionBtn transition duration-150 ease-in-out flex items-center whitespace-nowrap"
              type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
              Alerts
              <!-- <router-link to="/chart" @click="toggleModal()">Click me</router-link> -->
            </button>
          </div>


     

     

      <!-- <div class="dropdown relative justify-end pr-3">
        <button
          class="dropdown-toggle text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
         Jump To Date
        </button>

        <ul
          class="dropdown-menu min-w-max absolute hidden bg-[#2E3238] text-base z-50 float-left list-none text-center shadow-lg mt-1 hidden m-0 bg-clip-padding border-none"
          aria-labelledby="dropdownMenuButton1"
        >
         \
          <div v-if="showDatePicker" class="absolute mt-[1.5vh] z-[105]">
          <DatePicker v-model="chartDate" color="green" is-dark />
        </div>

        </ul>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import ChartEventNew from "@/components/vue_chart/chartEvent";
import moment from "moment";
import { EventBus } from '../../store/Eventbus.js'
let chartEvents;
chartEvents = new ChartEventNew();

export default {
  name: "ChartTopBar",
  components: {
    DatePicker,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      
      priceHighlight: true,
      selectedChartName: "",
      priceLine: true,
      crosshair: true,
      volume:true,
      showDatePicker: false,
      chartDate: "",
      toggle: false,
      isAlerts:true,
      chartName: [
        { id: "Bars", name: "Bar Chart" },
        { id: "LineChart", name: "Line Chart" },
        { id: "Candles", name: "CandleStick Chart" },
        // { id: "Kagi", name: "Kagi Chart" },
        { id: "HeikenAshi", name: "Heiken Ashi Char" },
        { id: "UpAndDown", name: "Up/Down Chart" },
        // { id: "PnF", name: "Point & Figure Chart" },
        { id: "BarTrend", name: "Bar Trend Chart" },
      ],
      timeFrames: [
        "D","W","M"
      ],
      rangeMap:[
        { range: "3M", toolTip: "3 Months of Visible Data" },
        { range: "6M", toolTip: "6 Months of Visible Data" },
        { range: "YTD", toolTip: "Year to Date Visible Data" },
        { range: "1Y", toolTip: "1 Year of Visible Data" },
        { range: "5Y", toolTip: "5 Year of Visible Data" },
        { range: "10Y", toolTip: "10 Year of Visible Data" },
        { range: "20Y", toolTip: "20 Year of Visible Data" },
        { range: "All", toolTip: "All Visible Data" },
      ],
      selectedRange: "1Y",
      defaultSelectedRange: "1Y",
      timeRange:"",
      dataType:[
        "Index",
        "Time"
      ]      
    };
  },
  mutations:{
    isAlerts:false,
    updateview (state, payload) {
     state.defaulAlerts= !this.isAlerts;
   }
  },
  watch: {
    selectedRange(n,o){
      console.log("chart range",n)
    },
    chartDate() {
      if (this.chartDate) {
        this.handleChartDate();
      }
    },
  },
  props:["exchange","auto_y_axis"],
  methods: {
    ...mapActions([
      "setNewChartType",
      "goToDateChart",
    ]),

    sendData(event) {
      this.isAlerts=!this.isAlerts;
      console.log(this.isAlerts,'toggle default button')
      this.$store.dispatch('updateDefaultAlertsData', this.isAlerts);
      EventBus.sharedData = this.isAlerts; // Set the shared data
      EventBus.$emit('dataReceived', this.isAlerts); // Emit event as well
    },
    updateDataType(d){
      this.$store.commit('SET_DATA_TYPE',d)
    },
    handleFocusOut() {
      this.showDatePicker = false;
    },
    handleOpenLoadDateModel() {},
    handleChartDate() {
      let convertedDate = new Date(this.chartDate);
      // console.log("date",convertedDate.getTime())
      this.goToDateChart(convertedDate.getTime());
      this.showDatePicker = false;
      this.chartDate = "";
    },
    handleToggleDropDown() {
      this.toggle = !this.toggle;
    },
    handlePriceLineCheck() {
      this.priceLine = !this.priceLine;
      this.$emit("togglePriceLine", this.priceLine);
    },
   handleVolumeCheck(){
      this.$store.commit("TOGGLE_CHART_VOLUME");
      // chartEvents.$emit('setChartRange',this.selectedRange);
    },
    handleAutoCheck(){
      chartEvents.$emit('toggleSideBarYAxis')
    },
   handlePriceHighlightCheck() {
      this.priceHighlight = !this.priceHighlight;
      this.$emit("togglePriceHighlight", this.priceHighlight);
    },
    handleCrosshair() {
      this.crosshair = !this.crosshair;
      this.$emit("toggleCrosshair", this.crosshair);
    },
    showCalendar() {
      this.showDatePicker = !this.showDatePicker;
    },
    handleSelectedChart(item) {
      this.selectedChartName = item;
      this.$emit("updateChartType",item)
      console.log("updateChartType",this.selectedChartName);
    },


    
    async selectTimeFrame(i) {
     
      this.$store.commit("SET_CHART_LOADING", true);
      this.$store.commit("SET_TIME_FRAME", i);
      this.$store.dispatch("updateChartConfig",{
        timeFrame:i
      })
      this.$emit("getChartData",this.getSelectedSymbol,i)
      
    },







    RangeSelected(index) {
      let selectedRange = this.rangeMap[index].range;
      this.timeRange = this.rangeMap[index].range;
      console.log("range",index)
      // this.selectedRange = selectedRange;
      console.log("selected range",this.selectedRange)
      chartEvents.$emit('setChartRange',selectedRange);
    },
    showChart_Modal() {
      this.showDatePicker=false
      this.$emit("showChartModal");
    },
  },
  computed: {
    ...mapGetters(["chartVisibleRange","getTimeFrame","getSelectedSymbol", "dc","showChartVolume","defineRangeInDate","selectedDataTypeDesc","symbolName","getChartType"]),
    selectedTimeFrame(){
      return this.getTimeFrame
    }
  },
  mounted(){
      // console.log(this.exchange)
      this.timeRange = this.chartVisibleRange
      this.selectedChartName = this.getChartType    
  }
};
</script>
<style>
.color-button{
  padding:  2px 16px ;
  font-size: 10px;
}
.default{
border: 1px solid #898d96;
}
</style>