<script>
import { Overlay } from 'trading-vue-js'
import { getIndicatorSettings } from '@/extensions/indicatorSettings/defaultIndicatorSettings';
export default {
    name: 'ATR',
    mixins: [Overlay],
    methods: {
        meta_info() {
            const indicatorName = 'ATR';
            const { period, lineWidth, lineType, color } = getIndicatorSettings(indicatorName);
            return {
                author: 'StdSquad', version: '1.0.0',
                desc: 'Average True Range',
                preset: {
                    name: 'ATR',
                    side: 'offchart',
                    settings: {
                        lineWidth,
                        color,
                        lineType,
                        period
                    }
                }
            }
        },
        use_for() { return ['ATR'] },
        calc() {
            return {
                props: {
                    days: { def: this.meta_info().preset.settings.period, text: 'days' }
                },
                conf: { renderer: 'Spline' },
                update: `
                    return atr(days)[0]
                `
            }
        }
    }
}
</script>



