<template>
  <span>
    <input
      :ref="fedRef"
      v-if="type === 'text' || !type"
      v-model="selectedColor"
      class="tvjs-std-input p-4 flex bg-transparent block w-full text-gray-200 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500"
      :style="style"
      :placeholder="name"
      @change="$emit('change', $event.target.value)"
      @input="$emit('input', $event.target.value)"
      required
    />
    <input
      :ref="fedRef"
      v-else-if="type === 'number'"
      :value="value"
      class="tvjs-std-input p-4 flex bg-transparent block w-full text-gray-200 border border-gray-700 shadow-inner rounded-md leading-tight focus:outline-none focus:border-gray-500"
      :style="style"
      :placeholder="name"
      @change="$emit('change', $event.target.value)"
      @input="$emit('input', $event.target.value)"
      :step="label == 'Fast' || label ==  'Slow'|| label ==  'Period' || label ==  'Days' ? 1 : 0.1"
      type="number"
      :min="label == 'Fast' || label ==  'Slow' || label ==  'Period' || label ==  'Days' ? 1 : 0.1"
     
      required
    />
    <!-- max="1.5" -->
    
      <!-- :step="step || 0.1" -->
    <div
      v-else-if="type === 'color'"
      class="tvjs-std-input"
      :style="colorPickerStyle"
    >
      <color-picker
        :ref="fedRef"
        :placeholder="name"
        v-model="selectedColor"
        invisibleBackground
        style="width: 100%; height: 100%"
      />
    </div>
    <select
      :ref="fedRef"
      v-else-if="type === 'select'"
      class="tvjs-std-input"
      :style="style"
      v-model="selectedColor"
      @input="$emit('input', $event.target.value)"
    >
      <option v-for="opt in list" :key="opt">{{ opt }}</option>
    </select>
    <select
      :ref="fedRef"
      v-else-if="type === 'select-linetype'"
      class="tvjs-std-input"
      :style="style"
      v-model="selectedLineStyle"
      @input="$emit('input', $event.target.value)"
    >
      <option>solid</option>
      <option>dotted</option>
      <option>dashed</option>
    </select>
  </span>
</template>

<script>
import ColorPicker from "@/components/color_picker/ColorPicker.vue";
export default {
  name: "StdInput",
  props: ["value", "name", "type", "list", "colors", "step", "fedRef","label"],
  methods: {},
  components: {
    ColorPicker,
  },
  computed: {
    style() {
      return {
        //background: this.$props.colors.back,
        //color: this.$props.colors.text
      };
    },
    selectedColor: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    selectedLineStyle: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    colorPickerStyle() {
      return `background: ${this.selectedColor}; padding: 3px`;
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
.tvjs-std-input {
  margin: 5px;
  background-color: transparent;
  border: 1px solid #353940;
  height: 22px;
  border-radius: 3px;
  padding: 2px 0 3px 10px;
  color: rgb(143, 149, 160);
  font-size: 1em;
  outline: none;
  width: 100px;
  font-family: Trebuchet MS, roboto, ubuntu, sans-serif;
}

select.tvjs-std-input {
  height: 29px;
  -moz-appearance: none;
}

.tvjs-std-input::placeholder {
  color: #8e909a;
  opacity: 0.25;
  font-family: Trebuchet MS, roboto, ubuntu, sans-serif;
}
</style>
