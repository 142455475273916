import {DataCube} from "trading-vue-js";
const dataCube = new DataCube({
  chart: {data: [], type: "Candles", tf: "1D"}, onchart: [], offchart: []
});
// export default function getDataCube(){
//   return dataCube
// }

let instance = null;

export default class DataCubeNew {
  constructor(props = {type: "Candles", tf: "1D",data: []},onchart = [],offchart= []) {
    // console.log("DataCube",instance)
    // if (!instance) {
    //   instance = dataCube;
    // }

    return new DataCube({
      chart: {...props}, onchart,offchart 
    });
  }

  // Other methods and properties of the singleton class
}