<template>
  <div
    ref="chartSideRef"
    class="max-w-[2.5vw] min-w-[2.5vw] h-full bg-[#151515] inline pt-[.7vw]"
  >
    <div class="flex justify-center items-center w-full flex-col gap-container " :style="{ gap: gapSize }">

      <!-- <h1 class="font-bold text-white text-[.7rem]">
        {{this.symbolName}}
      </h1> -->

      <!-- <button class="w-[50%] h-[50%]"></button> -->

      <button
        class="w-[50%] h-[50%]"
        @click="selectTool('RangeTool:PriceTime')"
        title="Range Tool"
      >
        <svg
          id="trend-line"
          xmlns="http://www.w3.org/2000/svg"
          class="w-[100%] h-[100%] "
          viewBox="0 0 20.057 20.057"
        >
          <rect
            id="Rectangle_3"
            data-name="Rectangle 3"
            width="2.985"
            height="3.044"
            transform="translate(1 16.013)"
            fill="none"
            stroke="#A5A9B2"
            stroke-width="1"
          />
          <rect
            id="Rectangle_4"
            data-name="Rectangle 4"
            width="2.984"
            height="3.044"
            transform="translate(16.073 1)"
            fill="none"
            stroke="#A5A9B2"
            stroke-width="1"
          />
          <line
            id="Line_23"
            data-name="Line 23"
            y1="12.728"
            x2="12.728"
            transform="translate(3.985 3.285)"
            stroke="#A5A9B2"
            stroke-width="1"
          />
        </svg>
      </button>

      <button class="w-[50%] h-[50%]" @click="selectTool('TrendLine:Trend')" title="TrendLine Tool" >
        
        <svg
          id="trend-line"
          xmlns="http://www.w3.org/2000/svg"
          class="w-[100%] h-[100%]"
          viewBox="0 0 20.057 20.057"
        >
          <rect
            id="Rectangle_3"
            data-name="Rectangle 3"
            width="0"
            height="3.044"
            transform="translate(1 16.013)"
            fill="none"
            stroke="#A5A9B2"
            stroke-width="1"
          />
          <rect
            id="Rectangle_4"
            data-name="Rectangle 4"
            width="0"
            height="3.044"
            transform="translate(16.073 1)"
            fill="none"
            stroke="#A5A9B2"
            stroke-width="1"
          />
          <line
            id="Line_23"
            data-name="Line 23"
            y1="15.728"
            x2="15.728"
            transform="translate(3.985 3.285)"
            stroke="#A5A9B2"
            stroke-width="1"
          />
        </svg>
      </button>

      <button class="w-[50%] h-[2.5vh]" @click="selectTool('HLineTool:Segment')" title="HLine Tool">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-[100%] h-[100%]"
          viewBox="0 0 20.057 5.044"
        >
          <g id="horizontal-line" transform="translate(0 -7.8)">
            <rect
              id="Rectangle_1"
              data-name="Rectangle 1"
              width="2.985"
              height="3.044"
              transform="translate(1 8.8)"
              fill="none"
              stroke="#A5A9B2"
              stroke-width="1"
            />
            <rect
              id="Rectangle_2"
              data-name="Rectangle 2"
              width="2.984"
              height="3.044"
              transform="translate(16.073 8.8)"
              fill="none"
              stroke="#A5A9B2"
              stroke-width="1"
            />
            <line
              id="Line_17"
              data-name="Line 17"
              x2="12.728"
              transform="translate(3.985 10.35)"
              stroke="#A5A9B2"
              stroke-width="1"
            />
          </g>
        </svg>
      </button>

      <!--<button class="w-[50%] h-[50%]" @click="selectTool('TextTool:Label')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.2"
          stroke="#A5A9B2"
          class="w-[100%] h-[100%]"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
          />
        </svg>
      </button>-->

      <button
        class="w-[50%] h-[50%]"
        title="Rectangle Tool"
        @click="selectTool('RectangleTool:Shape')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-[100%] h-[100%]"
          viewBox="0 0 20 20"
        >
          <rect
            id="rectangle"
            width="18"
            height="18"
            transform="translate(1 1)"
            fill="none"
            stroke="#A5A9B2"
            stroke-width="1"
          />
        </svg>
      </button>

      <button
        class="w-[50%] h-[50%]"
         title="Channel Tool"
        @click="selectTool('ChannelTool:Segment')"
      >
        <svg
          id="parallel-line"
          xmlns="http://www.w3.org/2000/svg"
          class="w-[100%] h-[100%]"
          viewBox="0 0 20 20"
        >
          <line
            id="Line_18"
            data-name="Line 18"
            x2="20"
            transform="translate(0 1)"
            stroke="#A5A9B2"
            stroke-width="1"
          />
          <line
            id="Line_19"
            data-name="Line 19"
            x2="20"
            transform="translate(0 19)"
            stroke="#A5A9B2"
            stroke-width="1"
          />
        </svg>
      </button>

      <button
        class="w-[50%] h-[50%]"
         title="Fibonacci Retrace"
        @click="selectTool('FibonacciRetrace:Segment')"
      >
        <svg
          id="fib-retracement"
          xmlns="http://www.w3.org/2000/svg"
          class="w-[100%] h-[100%]"
          viewBox="0 0 20.015 20.015"
        >
          <line
            id="Line_13"
            data-name="Line 13"
            x2="20"
            transform="translate(0.015 1)"
            stroke="#A5A9B2"
            stroke-width="1"
          />
          <line
            id="Line_14"
            data-name="Line 14"
            x2="20"
            transform="translate(0.015 19.015)"
            stroke="#A5A9B2"
            stroke-width="1"
          />
          <line
            id="Line_15"
            data-name="Line 15"
            x2="20"
            transform="translate(0 10)"
            stroke="#A5A9B2"
            stroke-width="1"
          />
          <line
            id="Line_16"
            data-name="Line 16"
            y1="17.678"
            x2="17.677"
            transform="translate(1.146 1.249)"
            stroke="#A5A9B2"
            stroke-width="1"
          />
        </svg>
      </button>

      <!-- <button
        class="w-[50%] h-[50%]"
        title="TradeVisualizer Tool"
        @click="selectTool('TradeVisualizerTool:PL')"
      >
        <svg
          id="trade-planner"
          xmlns="http://www.w3.org/2000/svg"
          class="w-[100%] h-[100%]"
          viewBox="0 0 20.015 20.015"
        >
          <line
            id="Line_20"
            data-name="Line 20"
            x2="20"
            transform="translate(0.015 1)"
            stroke="#A5A9B2"
            stroke-width="1"
            class="svg1"
          />
          <line
            id="Line_21"
            data-name="Line 21"
            x2="20"
            transform="translate(0.015 19.015)"
            stroke="#A5A9B2"
            stroke-width="1"
            class="svg1"
          />
          <line
            id="Line_22"
            data-name="Line 22"
            x2="20"
            transform="translate(0 11)"
            stroke="#A5A9B2"
            stroke-width="1"
            class="svg1"
          />
        </svg>
      </button> -->


      

      <!--<button
        class="w-[50%] h-[50%]"
        @click="selectTool('TrailingStopTool:PL')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-[100%] h-[100%]"
          viewBox="0 0 19.877 19.98"
        >
          <g id="trailingstop" transform="translate(-0.111 -0.018)">
            <line
              id="line5-0"
              x2="5.884"
              transform="translate(7.066 10.418)"
              stroke="#A5A9B2"
              stroke-width="1"
            />
            <line
              id="line5-0-1"
              x2="5.884"
              transform="translate(14.105 1.018)"
              stroke="#A5A9B2"
              stroke-width="1"
            />
            <line
              id="line5-0-7"
              x2="5.884"
              transform="translate(0.111 18.997)"
              stroke="#A5A9B2"
              stroke-width="1"
            />
          </g>
        </svg>
      </button>-->

      <button class="w-[50%] h-[50%]" @click="deleteAllTools" title="Delete Tool">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-[100%] h-[100%]"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.2"
          stroke="#A5A9B2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
          />
        </svg>
      </button>
    </div>
    <!-- <ChartModal @HideModal="HideModal" :showModal="showModal" /> -->
    <!--    <SettingsWin @hideWindow="hideWindow" :showWindow="showWindow" />-->
    <!-- <StdInput @hideWindow="hideWindow" :showWindow="showWindow" /> -->
    <!-- <Window @hideWindow="hideWindow" :showWindow="showWindow" /> -->
  </div>
</template>

<script>
// import ChartModal from "./ChartModal.vue";
// import StdInput from "@/extensions/shared/StdInput.vue";
// import SettingsWin from "@/extensions/indicatorSettings/SettingsWin.vue";
// import Window from "@/extensions/shared/Window.vue";
import { mapGetters } from "vuex";
export default {
  name: "ChartSideBar",
  data() {
    return {
      showWindow: false,
    };
  },

  props: {
    gapSize: {
      type: String,
      // default: '2rem', // Default gap value
    },
  },
  computed: {
    gapClass() {
      return this.gap; // Use the gap prop to set the class
    },
  },
  methods: {
    selectTool(toolID) {
      console.log("tool id",toolID, this.tv);
      if (this.tv) {
        this.tv.custom_event({
          event: "tool-selected",
          args: [toolID],
        });
      }
    },
     deleteAllTools() {
        this.dc.del('Tool')
        this.dc.del('Fibonacci')
        this.dc.del('Trend')
        
        this.tv.custom_event({ event: 'remove-all-tools-event', args: [] })
        setTimeout(() => {
            this.tv.resetChart(true)
          })
    },
    hideWindow() {
      this.showWindow = false;
    },
    showChartModal() {
      this.showWindow = true;
      console.log("CLICK... sj");
    },
    onToolRemoved() {
      this.toolRemoved = false;
    },
  },
  components: {
    // ChartModal,
    // StdInput,
    // SettingsWin,
  },
  computed: {
    ...mapGetters(["tv","dc","symbolName"]),
  },
  updated() {
    console.log("update chart side bar comp");
  },
  mounted() {
    if (this.tv) {
      this.tv.$on("remove-tool", this.onToolRemoved);
    }
  },
};
</script>
<style scoped>
button{
  padding: .15vw;
}
button:hover{
border: 1px solid #A5A9B2;
}
button:focus{
border: 1px solid #A5A9B2;
border-radius:4px;
}
@media (min-width: 968px) {
  .gap-container {
    gap:0.5rem; 
  }
}

</style>