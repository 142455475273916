<script>

import { Overlay } from 'trading-vue-js'
import { getIndicatorSettings } from '@/extensions/indicatorSettings/defaultIndicatorSettings';
export default {
    name: 'WilliamsR',
    mixins: [Overlay],
    methods: {
        meta_info() {
            const { lineWidth, color ,bandColor,backColor} = getIndicatorSettings("WilliamsR");
            return {
                author: 'StdSquad', version: '1.0.0',
                desc: 'Williams %R',
                preset: {
                    name: '%R',
                    side: 'offchart',
                    settings: {
                        lineWidth,
                        color,
                        bandColor,
                        backColor,
                        upper: -20,
                        lower: -80,
                        
                    }
                }
            }
        },
        use_for() { return ['WilliamsR'] },
        calc() {
            const { period } = getIndicatorSettings("WilliamsR");
            return {
                props: {
                    days: { def: period, text: 'days' }
                },
                conf: { renderer: 'Range' },
                update: `
                    return wpr(days)[0]
                `
            }
        }
    }
}
</script>