<script>

import { Overlay } from 'trading-vue-js'
import { getIndicatorSettings } from '@/extensions/indicatorSettings/defaultIndicatorSettings';
export default {
    name: 'RSI',
    mixins: [Overlay],
    methods: {
        meta_info() {
            const indicatorName = 'RSI';
            const { lineWidth, color ,bandColor,backColor} = getIndicatorSettings(indicatorName);
            return {
                author: 'StdSquad', version: '1.0.0',
                desc: 'Relative Strength Index',
                preset: {
                    name: 'RSI',
                    side: 'offchart',
                    settings: {
                        lineWidth,
                        color,
                        bandColor,
                        backColor,
                        
                    }
                }
            }
        },
        use_for() { return ['RSI'] },
        calc() {
            const { period } = getIndicatorSettings('RSI');
            return {
                props: {
                    days: { def: period, text: 'days' }
                },
                conf: { renderer: 'Range' },
                update: `return rsi(close, days)[0]`
            }
        }
    }
}
</script>