// Extension's controller

import { Utils } from 'trading-vue-js'
import ToolSettings from './ToolSettings.vue'
// import store from "@/store";
export default class Main {
  constructor(tv, dc) {
    this.widgets = {}
    this.tv = tv
    this.dc = dc
  }

  // Listens to all tvjs events, creates new widgets
  update(e) {
    // console.log('e.event == ',e.event," *** ",e.args)
    if (e.event === 'grid-mousedown' || e.event === 'remove-all-tools-event') {
      this.removeAllWidgets()
    } else if (e.event === 'line-is-changed') {
      const ref = e.args[0]
      const gridRef = e.args[1]
      const toolId = ref.split('-')[0]
      const type = ref.split('.', 1)?.[0] || 'onchart'
      const tool = this.dc.data[type].find(tool => tool.id === toolId)
      if(tool){
        tool.gridRef = gridRef

       /* if (tool.settings && tool.settings.p1 && tool.settings.p1[0]){
        //    verify point 1
          let dataIndex = this.dc.data.chart.data[tool.settings.p1[0]]
          // console.log(dataIndex)
          if(dataIndex && dataIndex[0]){
            tool.settings.p1Time = dataIndex[0]
            if(tool.settings.p1.length === 2){
              tool.settings.p1.push(dataIndex[0])
            }else{
              tool.settings.p1[2]= dataIndex[0]
            }
          }
        }
        if (tool.settings && tool.settings.p2 && tool.settings.p2[0]){
        //    verify point 1
          let dataIndex = this.dc.data.chart.data[tool.settings.p2[0]]
          // console.log(dataIndex)
          if(dataIndex && dataIndex[0]){
            tool.settings.p2Time = dataIndex[0]
            if(tool.settings.p2.length === 2){
              tool.settings.p2.push(dataIndex[0])
            }else{
              tool.settings.p2[2]= dataIndex[0]
            }
          }
        }*/
        // const data = JSON.stringify(tool)
        //   console.log(JSON.stringify(tool.settings.p1))
        // store.dispatch('updateDrawing', {data,ref})
      }

      // store.actions.updateDrawing(data)



    } else if (e.event === 'remove-tool') {
      this.removeAllWidgets()
      /*store.dispatch('deleteDrawing', this.dc.data.selected).then(res => {
        console.log('resp',res)
        if(res){
          const toolId = e.args[2].split('-')[0]
      const type = e.args[2]?.split('.', 1)?.[0] || 'onchart'
      const tool = this.dc.data[type].find(tool => tool.id === toolId)
      const isSettingsWindowAlreadyOpened = Object.values(this.widgets).find(
        x => x.data.tool === tool
      )
      if(isSettingsWindowAlreadyOpened && isSettingsWindowAlreadyOpened.id){
        this.tv.$delete(this.widgets, isSettingsWindowAlreadyOpened.id)
  
      }else{
        this.tv.$delete(this.widgets)
      
      }
        }
      })*/
      

    } else if (
      e.event ===
      'object-selected' /*|| e.event === 'tool-selected' || e.event === 'drawing-mode-off'*/
    ) {
      let id = `ToolSettings-${Utils.uuid2()}`

      this.removeAllWidgets()
      try {
        const type = e.args[2]?.split('.', 1)?.[0] || 'onchart'
        let toolId =  e.args[1].replace("_","")
        toolId = `${type}.${toolId}`
        // const toolType = e.args[1].split('_',1)?.[0]
        const tool = this.dc.data[type].find(tool => tool.id === toolId)
        // const toolByType = this.dc.data[type].find(tool => tool.type === toolType)
        const isSettingsWindowAlreadyOpened = Object.values(this.widgets).find(
          x => x.data.tool === tool
        )

        // console.log("toolId ",toolId,e.args,toolByType)
        if (isSettingsWindowAlreadyOpened) {
          // this.tv.$delete(this.widgets, isSettingsWindowAlreadyOpened.id)
          return
        }

        const lastSettingsStr = localStorage.getItem('toolSettingsMetaData')
        const lastLocation = lastSettingsStr
          ? JSON.parse(lastSettingsStr)
          : { x: 100, y: 100 }

        //--- show the settings window
        this.tv.$set(this.widgets, id, {
          id,
          cls: ToolSettings,
          data: {
            tool,
            lastLocation,
            openDialog: tool.settings?.firstRun || false
          }
        })

        //--- set flag to false, so that the input dialog box won't show up every time
        tool.settings.firstRun = false
      } catch (e) {
        console.log(e)
      }
    }
  }
 
  removeAllWidgets() {
    // console.log("removeAllWidgets")
    Object.values(this.widgets).forEach(tool => {
      this.tv.$delete(this.widgets, tool.id)
    })
  }

  remove_widget(id) {
    // console.log("remove_widget",id)
    // debugger
    // console.log(Object.values(this.widgets))
    this.tv.$delete(this.widgets, id)
  }
  // drag_update(){
  //   console.log("drag_update == ")
  // }
}
