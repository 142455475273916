
<script>
import { Overlay } from 'trading-vue-js'
export default {
  name: 'Noidea',
  mixins: [Overlay],
  data(){
    return {
      rows:6
    }
  },
  methods:{
    meta_info() {
      return {
        author: 'LakshanPerera',
        version: '1.0.2',
        desc: 'Noidea',
        preset: {
          
        }
      }
    },
    draw(ctx){
      const layout = this.$props.layout
      console.log("layout",layout)
      let d = layout.height
      ctx.translate(d/2, d/2);
      ctx.rotate(Math.PI * 360/360);
      ctx.lineWidth = Math.ceil(d / 50);
      ctx.lineCap = 'square';

      for(var i=0; i<=360; i++) {
        ctx.save();

        ctx.rotate((Math.PI * i/180));
        //ctx.translate(-ctx.lineWidth/2, ctx.lineWidth/2);

        ctx.beginPath();
        ctx.moveTo(0, 0);
        let opacity = (360 - (i * 0.95)) / 360;
        ctx.strokeStyle = 'rgba(255,255,255,' + opacity.toFixed(2) +')';
        ctx.lineTo(0, d + 30);
        ctx.stroke();
        ctx.closePath();

        ctx.restore();
      }

      ctx.globalCompositeOperation ='source-out';
      ctx.beginPath();
      ctx.arc(0, 0, d/2, 2 * Math.PI, false);
      ctx.fillStyle = 'white';
      ctx.fill();

      ctx.globalCompositeOperation ='destination-out';
      ctx.beginPath();
      ctx.arc(0, 0, (d/2) * .9, 2 * Math.PI, false);
      ctx.fill();
    },
    use_for() { return ['Noidea'] },
  }
}
</script>