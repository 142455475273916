import axios from "../axios";
import { tableData,ALL_JOBS, stockAlertableData,DATA_DELAYED } from "./apiRoutes";

export default {
  fetchTableData: async (payload) => {
      const response = await axios.post(tableData, payload);
      return response;
  },
  checkDataDelayed: async (payload) => {
      const response = await axios.post(DATA_DELAYED, payload);
      return response;
  },
  fetchStockAlertData: async (payload) => {
      const response = await axios.post(stockAlertableData, payload);
      return response;
  },

  jobData: async ()=>{
    try {
        const res = await axios.get('https://stg-api.marketalertpro.com/admin/get-configuration')  
        if(res?.status === 200){
            return res?.data?.payload
        }else{
            return false
        }           
    } catch (error) {
        return false
    }
},
};
