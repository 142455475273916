<script>
import { Overlay } from 'trading-vue-js'
import {mapGetters, mapMutations} from 'vuex'
import TCI from './calculators/tci'
import moment from 'moment'
import { getIndicatorSettings } from '@/extensions/indicatorSettings/defaultIndicatorSettings';
const tci_period = 34;
export default {
  name: 'TCI',
  mixins: [Overlay],
  computed: {
    ...mapGetters(['getSymbolData', 'getTimeFrame', 'symbolName',"barTrendChart"]),
    getOhlcv() {
      return this.getSymbolData
    },
    getTCI(){
      return this.tci
    },
    sett() {
      return this.$props.settings
    },
    lineWidth() {
      return this.sett.lineWidth || 1
    },
    color() {
      return this.sett.color || 'rgba(39, 128, 227, 1)'
    },
    bandColor() {
      return this.sett.bandColor || 'rgba(39, 128, 227, 0.1)'
    },
    period() {

      return this.sett.period || tci_period
    },
    symId() {
      return `tci-${this.symbolName}-${this.getTimeFrame}-${this.period}`
    }
  },
  watch: {
    getOhlcv: {
      handler(newValue,oldValue) {
        if(newValue === oldValue) return
        this.init()
      },
      deep: true
    },
    period(n,o){
      // console.log("period",n,o)
      this.UPDATE_TCI_PARAMS(n)
    }
  },
  methods: {
    ...mapMutations(["UPDATE_TCI_PARAMS"]),
    meta_info() {
      const {  lineWidth, color,bandColor } = getIndicatorSettings('TCI');
      return {
        author: 'LakshanPerera',
        version: '1.0.0',
        desc: 'Trend Channel Indicator',
        preset: {
          name: 'TCI $period',
          side: 'onchart',
          settings: {
            lineWidth:parseFloat(lineWidth),
            color,
            bandColor,
          }
        }
      }
    },

    init() {
      if (this.getOhlcv) {
        console.log("period this.barTrendChart",this.barTrendChart)
        this.period = this.barTrendChart ?? 34
        this.wholeData = this.getOhlcv.map(arr => (
          {
            format: moment(arr[0]).format("yyyy-MM-DD"),
            date: arr[0],
          open: arr[1],
          high: arr[2],
          low: arr[3],
          close: arr[4],
          volume: arr[5]
          }
        )).filter(d => !isNaN(d.high)|| !isNaN(d.low))

        this.tci = new TCI()
        this.prevSymId = this.symId
        this.tci.Calculate(
          this.wholeData,
          this.wholeData.length - 1,
          this.period
        )
      }
    },
    draw(ctx) {
      if (this.prevSymId !== this.symId) {
        this.init()
      }
      const { t2screen, $2screen } = this.$props.layout
      if (!this.getOhlcv || this.tci.Turns?.length === 0) return
      // console.log("turn==> turns",this.tci.Turns)
      // console.log("turn==> ohlc",this.getOhlcv)
      //   console.log("draw called")
      for (const turn of this.tci.Turns) {
        // const shouldSkip = !Object.values(turn).every(curr => Boolean(curr) && isFinite(curr))
        // if (shouldSkip) {
        //   continue
        // }

        const { start, end, startHigh, endHigh, startLow, endLow,startIndex } = turn

        if(isNaN(start)|| isNaN(end)|| isNaN(startHigh)|| isNaN(endHigh)|| isNaN(startLow)|| isNaN(endLow)){
          continue;
        }
        let secondToLastVal = 0;
        let secondToLast = this.wholeData?.[startIndex+1]
        if(secondToLast?.date){
          secondToLastVal = secondToLast?.date
        }
        const xStart = secondToLastVal ? t2screen(secondToLastVal):t2screen(start)
        const xEnd = t2screen(end)

        const yStartHigh = $2screen(startHigh)
        const yEndHigh = $2screen(endHigh)
        const yStartLow = $2screen(startLow)
        const yEndLow = $2screen(endLow)
        // console.log("draw called",{ xStart, xEnd,yStartHigh,yEndHigh,secondToLast })

        ctx.beginPath()
        ctx.fillStyle = this.bandColor
        ctx.moveTo(xStart, yStartHigh)
        ctx.lineTo(xEnd, yEndHigh)
        ctx.lineTo(xEnd, yEndLow)
        ctx.lineTo(xStart, yStartLow)
        ctx.fill()

        ctx.beginPath()
        ctx.lineWidth = this.lineWidth
        ctx.strokeStyle = this.color
        ctx.moveTo(xStart, yStartHigh)
        ctx.lineTo(xEnd, yEndHigh)

        ctx.moveTo(xStart, yStartLow)
        ctx.lineTo(xEnd, yEndLow)

        ctx.stroke()
      }
    },
    use_for() {
      return ['TCI']
    },
    calc() {
      const {  period } = getIndicatorSettings('TCI');
      return {
        props: {
          period: { def: parseInt(period), text: 'Period' }
        }
      }
    },

  }
}
</script>
