export const trendConfig = [
    {
        id: 1,
        name: "Trend",
        list: [
            {
                id: 1,
                name: "SMAX",
                // desc: "SMA(Price)",
                desc: "Simple Moving Average",
                selected: false,
                multi_value: true,
                props: [
                    {
                        length: 30,
                        color: "#0980e8",
                        // lineType:['Dotted','Dashed','Arrow']
                    },
                ],
                datatype: "object",
                storeRef: "sma",
                mapping: {
                    length: {prop: ["parameters", "period", "value"]},
                    color: {prop: "period", color: true},
                    // lineTypeee:{ prop:['Dotted','Dashed','Arrow']}
                },
                chartId: "simpleMovingAverage",
            },
            /* {
                  id: 2, name: "SMAOBV", desc: "SMA(OBV)", gridid: 1, selected: false, multi_value: true,
                  props: [
                      {
                          period: 30,
                          color: "#0980e8"
                      }
                  ],
                  datatype: "object",
                  storeRef: "smaobv",
                  mapping: {
                      period: {prop: ["parameters", "period", "value"]},
                      color: {prop: "period", color: true,},
                  },chartId:"simpleMovingAverageObv"
              },
              {
                  id: 3, name: "SMAVolume", desc: "SMA(Volume)", gridid: 1, selected: false, multi_value: true,
                  props: [
                      {
                          period: 30,
                          color: "#0980e8"
                      }
                  ],
                  datatype: "object",
                  storeRef: "smavolume",
                  mapping: {
                      period: {prop: ["parameters", "period", "value"]},
                      color: {prop: "period", color: true,},
                  },chartId:"simpleMovingAverageVolume"
              },*/
            {
                id: 4,
                name: "WMA",
                desc: "Weighted Moving Average",
                selected: false,
                multi_value: true,
                props: [
                    {
                        period: 9,
                        color: "#0980e8",
                    },
                    {
                        period: 27,
                        color: "#FF7518",
                    },
                ],
                datatype: "object",
                storeRef: "wma",
                mapping: {
                    period: {prop: ["parameters", "period", "value"]},
                    color: {prop: "period", color: true},
                },
                chartId: "weightedMovingAverage",
            },
            {
                id: 5,
                name: "XMA",
                desc: "Exponential Moving Average",
                selected: false,
                multi_value: true,
                props: [
                    {
                        period: 15,
                        color: "#0980e8",
                    },
                    {
                        period: 45,
                        color: "#FF7518",
                    },
                    {
                        period: 60,
                        color: "#9954BB",
                    },
                ],
                datatype: "object",
                storeRef: "xma",
                mapping: {
                    period: {prop: ["parameters", "period", "value"]},
                    color: {prop: "period", color: true},
                },
                chartId: "exponentialMovingAverage",
            },
            {
                id: 6,
                name: "SAR",
                desc: "Parabolic Stop and Reverse",
                selected: false,
                props: {
                    start: 0.2,
                    inc: 0.1,
                    max: 0.3,
                    color: "#35a9c6",
                },
                datatype: "object",
                mapping: {
                    start: {prop: ["parameters", "start", "value"]},
                    inc: {prop: ["parameters", "increment", "value"]},
                    max: {prop: ["parameters", "max", "value"]},
                    color: {prop: "series", color: true},
                },
                storeRef: "parabolicSar",
                chartId: "parabolicSar",
            },
            {
                id: 7,
                name: "MACD",
                desc: "Moving Average Convergence/Divergence",
                selected: false,
                props: {
                    fast: 30,
                    slow: 15,
                    smooth: 12,
                    macdColor: "#2772e3",
                    signalColor: "#441b1b",
                    histColor: "#54a0bb",
                },
                datatype: "object",
                storeRef: "macd",
                chartId: "macd",
                mapping: {
                    fast: {prop: ["parameters", "fastPeriod", "value"]},
                    slow: {prop: ["parameters", "slowPeriod", "value"]},
                    smooth: {prop: ["parameters", "signalPeriod", "value"]},
                    macdColor: {prop: "fastPeriod", color: true},
                    signalColor: {prop: "slowPeriod", color: true},
                    histColor: {prop: "signalPeriod", color: true},
                },
            },
        ],
    },
    {
        id: 2,
        name: "Momentum",
        list: [
            {
                id: 1,
                name: "RSI",
                desc: "Relative Strength Index",
                selected: false,
                props: {
                    length: 15,
                    color: "#ecca20",
                },
                datatype: "object",
                storeRef: "relativeStrengthIndex",
                mapping: {
                    length: {prop: ["parameters", "period", "value"]},
                    color: {prop: "period", color: true},
                },
                chartId: "relativeStrengthIndex",
            },
            {
                id: 2,
                name: "Stoch",
                desc: "Stochastic",
                selected: false,
                props: {
                    param_k: 14,
                    param_d: 3,
                    smooth: 3,
                    kColor: "#3782f2",
                    dColor: "#f48709",
                },
                datatype: "object",
                storeRef: "stochasticOscillator",
                mapping: {
                    param_k: {prop: ["parameters", "period", "value"]},
                    param_d: {prop: ["parameters", "kPeriod", "value"]},
                    smooth: {prop: ["parameters", "dPeriod", "value"]},
                    kColor: {prop: "kPeriod", color: true},
                    dColor: {prop: "dPeriod", color: true},
                },
                chartId: "stochasticOscillator",
            },
            {
                id: 3,
                name: "WilliamsR",
                desc: "Williams %R",
                selected: false,
                props: {
                    length: 14,
                    color: "#0980e8",
                },
                datatype: "object",
                storeRef: "williamsR",
                mapping: {
                    length: {prop: ["parameters", "period", "value"]},
                    color: {prop: "period", color: true},
                },
                chartId: "williamsR",
            },
            {
                id: 4,
                name: "TCI",
                desc: "Trend Channel Indicator",
                selected: false,
                props: {
                    length: 14,
                    color: "#0980e8",
                },
                datatype: "object",
                storeRef: "trendChannels",
                mapping: {
                    length: {prop: ["parameters", "period", "value"]},
                    color: {prop: "period", color: true},
                },
                chartId: "trendChannels",
            },
        ],
    },
    {
        id: 3,

        name: "Volatility",
        list: [
            {
                id: 1,
                name: "BB",
                desc: "Bollinger Bands",
                selected: false,
                props: {
                    length: 21,
                    stddev: 2,
                    color: "#0980e8",
                    backColor: "#2b86a6",
                },
                datatype: "object",
                storeRef: "bollingerBands",
                mapping: {
                    length: {prop: ["parameters", "period", "value"]},
                    stddev: {prop: ["parameters", "deviation", "value"]},
                    color: {prop: "period", color: true},
                    backColor: {prop: "deviation", color: true},
                },
                chartId: "bollingerBands",
            },
            {
                id: 2,
                name: "ATR",
                desc: "Average True Range",
                selected: false,
                props: {
                    length: 12,
                    lineWidth: 2,
                    color: "#0980e8",
                },
                datatype: "object",
                storeRef: "averageTrueRange",
                mapping: {
                    length: {prop: ["parameters", "period", "value"]},
                    lineWidth: {prop: ["parameters", "multiplier", "value"]},
                    color: {prop: "period", color: true},
                },
                chartId: "averageTrueRange",
            },
            {
                id: 3,
                name: "ROC",
                desc: "Rate of Change",
                selected: false,
                props: {
                    length: 14,
                    color: "#0980e8",
                },
                datatype: "object",
                storeRef: "rateOfChange",
                mapping: {
                    length: {prop: ["parameters", "period", "value"]},
                    color: {prop: "period", color: true},
                },
                chartId: "rateOfChange",
            },
            // {
            //   id: 4,
            //   name: "Value",
            //   desc: "Value Indicator",
            //   selected: false,
            //   props: {
            //     color: "#0980e8",
            //   },
            //   datatype: "object",
            //   storeRef: "valueTraded",
            //   mapping: {
            //     color: { prop: "series", color: true },
            //   },
            //   chartId: "valueTraded",
            // },
            {
                id: 4,
                name: "VWAP",
                desc: "VWAP indicator",
                selected: false,
                props: {
                    color: "#0980e8",
                },
                datatype: "object",
                storeRef: "vwap",
                mapping: {
                    color: {prop: "series", color: true},
                },
                chartId: "vwap",
            },
        ],
    },
];