<script>

import { Overlay } from 'trading-vue-js'
import { getIndicatorSettings } from '@/extensions/indicatorSettings/defaultIndicatorSettings';
export default {
    // eslint-disable-next-line vue/multi-word-component-names
  name: 'Stoch',
  mixins: [Overlay],
  methods: {
    meta_info() {
      
      const { lineWidth,kColor,dColor,bandColor,backColor } = getIndicatorSettings('Stoch');
      return {
        author: 'StdSquad', version: '1.0.0',
        desc: 'Stochastic',
        preset: {
          name: 'Stoch',
          side: 'offchart',
          settings: {
            lineWidth,
            kColor,
            dColor,
            bandColor,
            backColor
          }
        }
      }
    },
    draw(ctx) {

      const layout = this.$props.layout
      const upper = layout.$2screen(this.sett.upper || 70)
      const lower = layout.$2screen(this.sett.lower || 30)

      // K
      ctx.lineWidth = this.line_width
      ctx.strokeStyle = this.k_color
      ctx.beginPath()

      for (var p of this.$props.data) {
        let x = layout.t2screen(p[0])
        let y = layout.$2screen(p[1])
        ctx.lineTo(x, y)
      }

      ctx.stroke()

      // D
      ctx.lineWidth = this.line_width
      ctx.strokeStyle = this.d_color
      ctx.beginPath()

      for (let p of this.$props.data) {
        let x = layout.t2screen(p[0])
        let y = layout.$2screen(p[2])
        ctx.lineTo(x, y)
      }

      ctx.stroke()

      ctx.strokeStyle = this.band_color
      ctx.setLineDash([5]) // Will be removed after draw()
      ctx.beginPath()

      // Fill the area between the bands
      ctx.fillStyle = this.back_color
      ctx.fillRect(0, upper, layout.width, lower - upper)

      // Upper band
      ctx.moveTo(0, upper)
      ctx.lineTo(layout.width, upper)

      // Lower band
      ctx.moveTo(0, lower)
      ctx.lineTo(layout.width, lower)

      ctx.stroke()
    },
    use_for() { return ['Stoch'] },
    data_colors() { return [this.color] },
    y_range(hi, lo) {
      return [
        Math.max(hi, this.sett.upper || 70),
        Math.min(lo, this.sett.lower || 30)
      ]
    },
    calc() {
      const { param_k,param_d,smooth } = getIndicatorSettings('Stoch');
      return {
        props: {
          param_k: { def: param_k, text: 'K' },
          param_d: { def: param_d, text: 'D' },
          smooth: { def:smooth, text: 'Smooth' },
        },
        update: `
                    let k = sma(stoch(close, high, low, param_k), smooth)
                    let d = sma(k, param_d)
                    return [k[0], d[0]]
                `
      }
    }
  },
  computed: {
    sett() {
      return this.$props.settings
    },
    line_width() {
      return this.sett.lineWidth || 0.75
    },
    k_color() {
      return this.sett.kColor || '#3782f2'
    },
    d_color() {
      return this.sett.dColor || '#f48709'
    },
    band_color() {
      return this.sett.bandColor || '#ddd'
    },
    back_color() {
      return this.sett.backColor || '#381e9c16'
    }
  }

}
</script>
