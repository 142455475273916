<script>
import { Overlay } from 'trading-vue-js'
import { isDefined } from '../../utils'
import kagi from './calculators/kagiCalculator'
import XVolbar from './primitives/xvolbar.js'
import { mapGetters } from 'vuex'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
  name: 'Kagi',
  mixins: [Overlay],
  methods: {
    meta_info() {
      return {
        author: 'LakshanPerera',
        version: '1.0.0',
        desc: 'Kagi Chart'
      }
    },
    init() {
      if (this.getSymbolData) {
        this.originalData = this.getSymbolData
        this.wholeData = this.originalData.map(arr => ({
          date: arr[0],
          open: arr[1],
          high: arr[2],
          low: arr[3],
          close: arr[4],
          volume: arr[5]
        }))
        this.tv.goto(this.originalData.length - 1)
      }
    },
    draw(ctx) {
      if (!this.wholeData || !this.wholeData.length) return

      const strokeWidth = this.line_width * 3
      ctx.strokeStyle = this.color

      const layout = this.$props.layout
      const xScale = layout.t2screen
      const yScale = layout.$2screen
      const formattedData = this.wholeData
      const kagiAlgo = kagi()
      const processedData = kagiAlgo(formattedData)
      const stroke = { yin: 'green', yang: 'red' }
      const currentValueStroke = 'black'
      let begin = true

      processedData.forEach(each => {
        ctx.strokeStyle = stroke[each.type]
        ctx.lineWidth = strokeWidth

        ctx.beginPath()
        let prevX
        each.plot.forEach(d => {
          const [x, y] = [xScale(d[0]), yScale(d[1])]
          if (begin) {
            ctx.moveTo(x, y)
            begin = false
          } else {
            if (isDefined(prevX)) {
              ctx.lineTo(prevX, y)
            }
            ctx.lineTo(x, y)
          }
          prevX = x
        })
        ctx.stroke()
      })
      const lastPlot = processedData[processedData.length - 1].plot
      const last = lastPlot[lastPlot.length - 1]
      ctx.beginPath()
      // ctx.strokeStyle = "black";
      ctx.lineWidth = 1

      const [x, y1, y2] = [xScale(last[0]), yScale(last[2]), yScale(last[3])]
      // console.log(last, x, y);
      ctx.moveTo(x, y1)
      ctx.lineTo(x + 10, y1)
      ctx.stroke()

      ctx.beginPath()
      ctx.strokeStyle = currentValueStroke
      ctx.moveTo(x - 10, y2)
      ctx.lineTo(x, y2)
      ctx.stroke()

      // console.log(this.$props.layout.volume)
      // if (this.show_volume) {
      //   for (let i = 0; i < this.$props.layout.volume.length; i++) {
      //     let volumeData = this.$props.layout.volume[i]
      //     let barData = this.$props.layout.candles[i]
      //     new XVolbar(this, ctx, volumeData, barData)
      //   }
      // }
    },
    use_for() {
      return ['Kagi']
    },
    data_colors() {
      return [this.color]
    }
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters(['getSymbolData', 'tv']),
    sett() {
      return this.$props.settings
    },
    line_width() {
      return this.sett.lineWidth || 0.75
    },
    color() {
      const n = this.$props.num % 5
      return this.sett.color || this.COLORS[n]
    },
    data_index() {
      return this.sett.dataIndex || 1
    },
    // Don't connect separate parts if true
    skip_nan() {
      return this.sett.skipNaN
    },
    show_volume() {
      return false
    },
    upVolBarColor() {
      return this.sett.upBarColor || '#23a77655'
    },
    downVolBarColor() {
      return this.sett.downBarColor || '#e5415055'
    }
  },
  watch:{
    getSymbolData(n){
      // console.log("Kagi Updated")
      if (n) {
        this.originalData = n
        this.wholeData = this.originalData.map(arr => ({
          date: arr[0],
          open: arr[1],
          high: arr[2],
          low: arr[3],
          close: arr[4],
          volume: arr[5]
        }))
        this.tv.goto(this.originalData.length - 1)
      }
    }
  },
  data() {
    return {
      COLORS: ['#42b28a', '#5691ce', '#612ff9', '#d50b90', '#ff2316']
    }
  },
  mounted() {
    console.log("Kagi Mounted")
  }
}
</script>
