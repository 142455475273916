<template>
    <div ref="chartBottomRef" class="w-full h-[5vh] bg-[#151515]">
        
    </div>
</template>

<script>
export default {
    name: 'ChartBottom',
};
</script>